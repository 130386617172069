import { ERCStore } from "src/types/erc";
import { StoreApi, UseBoundStore, create } from "zustand";

export const createSelectedERCTab = (set: {
  (partial: ERCStore, replace?: boolean | undefined): void;
  (arg0: { selectedERCTab: string }): void;
}) => ({
  selectedERCTab: "entity types",
  setSelectedERCTab: (selectedERCTab: string) =>
    set({ selectedERCTab: selectedERCTab }),
});

export const useERCStore: UseBoundStore<StoreApi<ERCStore>> = create((set) => ({
  ...createSelectedERCTab(set),
}));
