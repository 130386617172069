import {
  faChevronDown,
  faChevronRight,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { KeyStringVal } from "src/types/general";
import Loader from "src/components/Loader/Loader";
import { pageSize } from "src/constants/general";
import TablePagination from "src/components/General/TablePagination";
import { GetCustomQuestions } from "src/services/third-party-risk/questions/custom-question-sets";
import { Disclosure } from "@headlessui/react";

const CustomQuestionSetDetail = ({
  selectedQuestionSet,
  setSelectedQuestionSet,
}: {
  selectedQuestionSet: KeyStringVal;
  setSelectedQuestionSet: (selectedQuestionSet: KeyStringVal) => void;
}) => {
  const [pageNumber, setPageNumber] = useState<number>(1);

  const questionSetID = sessionStorage.custom_question_set_id;

  const { data: questionSet, status: questionSetStatus } = GetCustomQuestions(
    questionSetID,
    pageNumber
  );

  const totalCount = questionSet?.pager?.total_results || 0;
  const totalPages = questionSet?.pager?.num_pages || 0;
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  const handleReturn = () => {
    sessionStorage.removeItem("custom_question_set_id");
    sessionStorage.removeItem("custom_question_set_name");
    setSelectedQuestionSet({});
  };

  return (
    <section className="flex flex-col flex-grow gap-5">
      <header className="flex items-center gap-5">
        <button
          className="flex gap-2 items-center w-max tracking-wide text-sm dark:text-checkbox dark:hover:text-checkbox/50 duration-100"
          onClick={handleReturn}
        >
          <FontAwesomeIcon icon={faArrowLeftLong} />
          <span>Return</span>
        </button>
        <h4 className="text-xl">{sessionStorage.custom_question_set_name}</h4>
      </header>
      {questionSetStatus === "loading" ? (
        <Loader />
      ) : questionSet?.data.length > 0 ? (
        <section className="flex flex-col flex-grow gap-5 pb-20">
          <ul className="flex flex-col flex-grow gap-5 text-base">
            {questionSet.data.map((qa: any, index: number) => {
              return (
                <li
                  key={index}
                  className="grid gap-5 p-5 dark:bg-gray-700 rounded-md"
                >
                  <h4>
                    {qa.question_mandatory && (
                      <span className="text-b1-reg text-red-500">*</span>
                    )}{" "}
                    Q: {qa.question}
                  </h4>

                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex items-center gap-2 text-sm">
                          <p>{open ? "Hide" : "Show"} Guidelines</p>
                          <FontAwesomeIcon
                            icon={open ? faChevronDown : faChevronRight}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel>
                          <article className="flex flex-col flex-grow gap-5">
                            {[
                              "Yes_No",
                              "Multiple Choice",
                              "Multiple Select",
                            ].includes(qa.question_type) && (
                              <section className="grid gap-1 w-full max-h-[30rem] overflow-auto scrollbar">
                                {qa.multi_choices?.map(
                                  (choice: KeyStringVal) => {
                                    const selected = qa.multi_answers.some(
                                      (selectedChoice) =>
                                        selectedChoice.answer === choice.choice
                                    );
                                    return (
                                      <article
                                        key={choice.choice}
                                        className="flex items-center gap-1"
                                      >
                                        {qa.question_type ===
                                        "Multiple Select" ? (
                                          <input
                                            type="checkbox"
                                            disabled
                                            className="form-checkbox w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                                            checked={selected}
                                          />
                                        ) : (
                                          <input
                                            type="radio"
                                            disabled
                                            className="form-radio w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                                            checked={selected}
                                          />
                                        )}
                                        <label htmlFor="">
                                          {choice.choice}
                                        </label>
                                      </article>
                                    );
                                  }
                                )}
                              </section>
                            )}
                            <p className="text-left p-3 break-words dark:bg-gray-900 rounded-md">
                              A: {qa.ideal_response}
                            </p>
                          </article>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  {qa.tags_list?.length > 0 && (
                    <article className="flex flex-wrap items-center gap-3 text-b2-reg">
                      <span>Tags</span>
                      {qa.tags_list.map((tag: string, index: number) => {
                        return (
                          <span
                            key={index}
                            className="flex items-center gap-3 px-4 py-1 dark:bg-gray-800 rounded"
                          >
                            {tag}
                          </span>
                        );
                      })}
                    </article>
                  )}
                </li>
              );
            })}
          </ul>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/third-party-risk-placeholder.svg"
            alt="questionSet placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-t1-bold">Custom Question Set</h4>
            <h4>No questions available</h4>
          </article>
        </section>
      )}
    </section>
  );
};

export default CustomQuestionSetDetail;
