import React from "react";
import { GetFrameworkControlMetadata } from "../../../../../services/erc/controls/framework-controls";
import MetadataField from "src/components/ERC/MetadataField";

const FrameworkControlMetadata = ({
  frameworkControlID,
}: {
  frameworkControlID: string;
}) => {
  const { data: frameworkControlMetadata } =
    GetFrameworkControlMetadata(frameworkControlID);

  return (
    <>
      {frameworkControlMetadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            <h2 className="text-subt1-semi">{frameworkControlMetadata.name}</h2>
            <p>{frameworkControlMetadata.control_description}</p>
            <section className="grid grid-cols-5 gap-x-20 gap-y-10">
              <MetadataField
                metadata={frameworkControlMetadata}
                label="Control Id"
                keyName="control_id"
              />
              <MetadataField
                metadata={frameworkControlMetadata}
                label="Sub Control Id"
                keyName="sub_control_id"
              />
              <MetadataField
                metadata={frameworkControlMetadata}
                label="Category"
                keyName="category"
              />
              <MetadataField
                metadata={frameworkControlMetadata}
                label="Subcategory"
                keyName="sub_category"
              />
              <MetadataField
                metadata={frameworkControlMetadata}
                label="Framework Authority"
                keyName="framework_authority_short_name"
              />
              <MetadataField
                metadata={frameworkControlMetadata}
                label="Framework Name"
                keyName="framework_short_name"
              />
              <MetadataField
                metadata={frameworkControlMetadata}
                label="Framework Version"
                keyName="framework_version"
              />
            </section>
          </section>
        </header>
      )}
    </>
  );
};

export default FrameworkControlMetadata;
