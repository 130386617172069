import React from "react";
import {
  convertToUTCString,
  getCustomerID,
  getEmailFromUserID,
  getNamefromUserID,
} from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetThreatMetadata } from "src/services/erc/risks/threats-vulns/threats";
import { GetAllUsers } from "src/services/settings/users";
import MetadataField from "src/components/ERC/MetadataField";
import ArchiveRestoreThreat from "./ArchiveRestoreThreat";
import UpdateThreat from "./UpdateThreat";

const ThreatMetadata = ({ threatID }: { threatID: string }) => {
  const customerID = getCustomerID();

  const { data: threatMetadata } = GetThreatMetadata(threatID);
  const { data: allUsers } = GetAllUsers(customerID, false);

  return (
    <>
      {threatMetadata && (
        <header className="grid gap-5">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-5">
              <article className="grid content-start gap-1">
                <h2 className="text-subt1-semi">{threatMetadata.name}</h2>
                <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                  <h4>
                    Last Updated by{" "}
                    {getNamefromUserID(
                      allUsers,
                      threatMetadata.last_updated_by
                    )}{" "}
                    (
                    {getEmailFromUserID(
                      allUsers,
                      threatMetadata.last_updated_by
                    )}
                    )
                  </h4>
                  <span>
                    | {convertToUTCString(threatMetadata.last_updated_at)}
                  </span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    Created by{" "}
                    {getNamefromUserID(allUsers, threatMetadata.created_by)} (
                    {getEmailFromUserID(allUsers, threatMetadata.created_by)})
                  </h4>
                  <span>| {convertToUTCString(threatMetadata.created_at)}</span>
                </article>
              </article>
              <article className="flex items-center gap-5">
                <UpdateThreat threatID={threatID} threat={threatMetadata} />
                {/* <ArchiveRestoreThreat
                  threatID={threatID}
                  threat={threatMetadata}
                /> */}
              </article>
            </header>
            <section className="flex flex-wrap items-center gap-5 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[threatMetadata.status.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[threatMetadata.status.toLowerCase()]
                  }`}
                >
                  {threatMetadata.status}
                </span>
              </article>
            </section>
            <MetadataField
              metadata={threatMetadata}
              label="Description"
              keyName="description"
            />
            <section className="grid grid-cols-5 gap-x-20 gap-y-10">
              <MetadataField
                metadata={threatMetadata}
                label="Serial Number"
                keyName="serial_number"
              />
              <MetadataField
                metadata={threatMetadata}
                label="Category"
                keyName="category"
              />
              <MetadataField
                metadata={threatMetadata}
                label="Subcategory"
                keyName="sub_category"
              />
              {threatMetadata.ref_ids &&
                Object.keys(threatMetadata.ref_ids).length > 0 && (
                  <article className="grid content-start gap-1">
                    <h4 className="dark:text-gray-300 text-b2-reg">Ref-Ids</h4>
                    <ul className="flex flex-col flex-grow gap-1">
                      {Object.entries(threatMetadata.ref_ids).map((keyVal) => {
                        return (
                          <li key={keyVal[0]} className="text-b1-semi">
                            {keyVal[0]}: {keyVal[1]}
                          </li>
                        );
                      })}
                    </ul>
                  </article>
                )}
              <MetadataField
                metadata={threatMetadata}
                label="Standard or Framework"
                keyName="framework_or_standard"
              />
            </section>
          </section>
        </header>
      )}
    </>
  );
};

export default ThreatMetadata;
