import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DropdownLayout from "src/layouts/DropdownLayout";
import { KeyStringVal } from "src/types/general";

const PoliciesFilter = ({
  selectedPolicyIDs,
  setSelectedPolicyIDs,
  policies,
}: {
  selectedPolicyIDs: string[];
  setSelectedPolicyIDs: (selectedPolicyIDs: string[]) => void;
  policies: any;
}) => {
  return (
    <DropdownLayout
      label="Policies"
      value={
        selectedPolicyIDs?.length > 0
          ? `${selectedPolicyIDs.length} selected`
          : ""
      }
      placeholder="Select"
      width="w-4/5"
    >
      <article className="flex items-center gap-5 p-4 divide-x dark:divide-checkbox">
        <button
          disabled={selectedPolicyIDs.length === policies?.data?.length}
          className="dark:disabled:text-filter dark:hover:text-checkbox/60 duration-100"
          onClick={() => {
            setSelectedPolicyIDs(
              policies?.data?.reduce(
                (pV: string[], cV: KeyStringVal) => [...pV, cV.policy_id],
                []
              )
            );
          }}
        >
          Select All
        </button>
        <button
          disabled={policies?.data?.length === 0}
          className="pl-5 dark:disabled:text-filter dark:hover:text-checkbox/60 duration-100"
          onClick={() => setSelectedPolicyIDs([])}
        >
          Deselect All
        </button>
      </article>
      {policies?.data?.map((policy: any, index: number) => {
        const selected = selectedPolicyIDs.includes(policy.policy_id);
        return (
          <button
            key={index}
            className="relative flex items-center gap-2 px-4 py-1 w-full break-words text-left dark:hover:bg-gray-600/70 duration-100"
            onClick={() => {
              if (selected)
                setSelectedPolicyIDs(
                  selectedPolicyIDs.filter(
                    (policyID) => policyID !== policy.policy_id
                  )
                );
              else
                setSelectedPolicyIDs([...selectedPolicyIDs, policy.policy_id]);
            }}
          >
            {selected && <FontAwesomeIcon icon={faCheck} className="text-no" />}
            <p>{policy.policy_name}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default PoliciesFilter;
