import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import TablePagination from "src/components/General/TablePagination";
import Loader from "src/components/Loader/Loader";
import { initialSort, pageSize, attributeColors } from "src/constants/general";
import TableLayout from "src/layouts/TableLayout";
import { GetRiskQuestions } from "src/services/erc/risks/assessments/questions";
import { Sort } from "src/types/dashboard";
import {
  sortRows,
  handleSort,
  isEpoch,
  convertToUTCShortString,
} from "src/utils/general";

const Questions = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sort, setSort] = useState<Sort>(initialSort);

  const { data: questions, status: questionsStatus } =
    GetRiskQuestions(pageNumber);

  const metadata = questions && JSON.parse(questions.header.metadata);
  const filteredMetadata = metadata?.required.filter((col: string) => {
    if (metadata.properties[col].hidden === "True") return null;
    return col;
  });
  const sortedRows = questions && sortRows(questions?.data, sort);

  const totalCount = questions?.pager.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <>
      {questionsStatus === "loading" ? (
        <Loader />
      ) : questions?.data?.length > 0 ? (
        <section className="flex flex-col flex-grow gap-5 pb-20">
          <TableLayout fullHeight>
            <thead className="sticky -top-1.5 dark:bg-gray-900">
              <tr>
                {filteredMetadata?.map((col: string) => {
                  const column = metadata.properties[col];
                  return (
                    <th
                      scope="col"
                      key={col}
                      className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                    >
                      <article className="capitalize flex gap-10 justify-between">
                        <h4 className="break-words">{column.title}</h4>
                        <button aria-label="sort">
                          <FontAwesomeIcon
                            icon={faSort}
                            className="mt-0.5 dark:text-gray-700"
                            onClick={() => handleSort(col, setSort)}
                          />
                        </button>
                      </article>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="divide-y dark:divide-gray-700">
              {sortedRows?.map((row: any, index: number) => {
                return (
                  <tr
                    key={index}
                    data-test="table-row"
                    className="relative p-5 gap-3 break-words dark:bg-gray-800"
                  >
                    {filteredMetadata?.map((col: string, colIndex: number) => {
                      const isDate = isEpoch(row[col]);

                      return (
                        <td
                          key={`${index}-${colIndex}`}
                          className="relative px-6 py-3 last:pr-16 text-left break-words"
                        >
                          {
                            <p
                              className={`${
                                attributeColors[String(row[col]).toLowerCase()]
                              }`}
                            >
                              {isDate
                                ? convertToUTCShortString(row[col])
                                : String(row[col]).replaceAll("_", " ")}
                            </p>
                          }
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </TableLayout>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/third-party-risk-placeholder.svg"
            alt="risk blueprints placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-xl font-extrabold">Questions</h4>
            <h4>No questions available</h4>
          </article>
        </section>
      )}
    </>
  );
};

export default Questions;
