/* eslint-disable react-hooks/exhaustive-deps */
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { AddGlobalQuestion } from "src/services/third-party-risk/questions/global-questions";
import RegularInput from "src/components/Input/RegularInput";
import ModalLayout from "src/layouts/ModalLayout";
import { GetQuestionTypes } from "../../../../../services/third-party-risk/questions/global-questions";
import RadioInput from "src/components/Input/RadioInput";
import { KeyStringVal } from "src/types/general";
import CheckboxInput from "src/components/Input/CheckboxInput";

const AskGlobalQuestion = () => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    question: "",
    question_type: "Text",
    weight: 1,
    description: "",
    question_mandatory: false,
    attachment_mandatory: false,
    multi_choices: [],
    multi_answers: [],
  });
  const [newChoice, setNewChoice] = useState("");

  const addQuestion = AddGlobalQuestion();
  const { data: questionTypes } = GetQuestionTypes();

  useEffect(() => {
    setInputs({
      ...inputs,
      multi_answers: [],
    });
  }, [inputs.question_type]);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        className="black-button"
        onClick={() => {
          setShow(true);
          setInputs({
            question: "",
            question_type: "Text",
            weight: 1,
            description: "",
            question_mandatory: false,
            attachment_mandatory: false,
            multi_choices: [],
            multi_answers: [],
          });
          setNewChoice("");
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add a Question</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Add a Question
          </h3>
          <RegularInput
            label="Question"
            keyName="question"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <RadioInput
            label="Question Type"
            keyName="question_type"
            list={questionTypes?.fields}
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <article className="flex flex-wrap items-center gap-5">
            <RegularInput
              label="Weight"
              keyName="weight"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CheckboxInput
              label="Question Mandatory"
              keyName="question_mandatory"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CheckboxInput
              label="Attachment Mandatory"
              keyName="attachment_mandatory"
              inputs={inputs}
              setInputs={setInputs}
            />
          </article>
          <RegularInput
            label="Description"
            keyName="description"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          {["Multiple Choice", "Multiple Select"].includes(
            inputs.question_type
          ) && (
            <section className="flex flex-col flex-grow gap-2">
              <section className="grid gap-1 px-1 w-full max-h-[30rem] overflow-auto scrollbar">
                {inputs.multi_choices?.map((choice: KeyStringVal) => {
                  const selected = inputs.multi_answers.some(
                    (selectedChoice) => selectedChoice.answer === choice.choice
                  );
                  return (
                    <article
                      key={choice.choice}
                      className="flex items-center gap-1"
                    >
                      {inputs.question_type === "Multiple Select" ? (
                        <input
                          type="checkbox"
                          className="form-checkbox w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                          checked={selected}
                          onChange={() => {
                            if (selected)
                              setInputs({
                                ...inputs,
                                multi_answers: inputs.multi_answers.filter(
                                  (curChoice) =>
                                    curChoice.answer !== choice.choice
                                ),
                              });
                            else
                              setInputs({
                                ...inputs,
                                multi_answers: [
                                  ...inputs.multi_answers,
                                  { answer: choice.choice },
                                ],
                              });
                          }}
                        />
                      ) : (
                        <input
                          type="radio"
                          className="form-radio w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                          checked={selected}
                          onChange={() =>
                            setSelectedChoices([{ answer: choice.choice }])
                          }
                        />
                      )}
                      <label htmlFor="">{choice.choice}</label>
                      <button
                        className="red-trash-button"
                        onClick={() =>
                          setInputs({
                            ...inputs,
                            multi_choices: inputs.multi_choices.filter(
                              (curChoice) => curChoice.choice !== choice.choice
                            ),
                            multi_answers: inputs.multi_answers.filter(
                              (curChoice) => curChoice.answer !== choice.choice
                            ),
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faXmark} className="mt-1" />
                      </button>
                    </article>
                  );
                })}
              </section>
              <article className="flex items-center gap-5">
                <textarea
                  value={newChoice}
                  placeholder="Add New Choice"
                  onChange={(e) => setNewChoice(e.target.value)}
                  className="px-4 py-2 w-40 h-12 overflow-hidden border-0 bg-transparent dark:hover:bg-gray-700 duration-100 dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 resize-none rounded"
                />
                {newChoice && (
                  <button
                    className="place-self-end small-blue-button"
                    onClick={() => {
                      setInputs({
                        ...inputs,
                        multi_choices: [
                          ...inputs.multi_choices,
                          { choice: newChoice },
                        ],
                      });
                      setNewChoice("");
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    Add
                  </button>
                )}
              </article>
            </section>
          )}
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                inputs.question === "" ||
                inputs.question_type === "" ||
                (["Multiple Choice", "Multiple Select"].includes(
                  inputs.question_type
                ) &&
                  inputs.multi_answers.length === 0)
              }
              className="blue-button"
              onClick={() => {
                addQuestion.mutate({
                  info: {
                    question: inputs.question,
                    question_type: inputs.question_type,
                    multi_choices: inputs.multi_choices,
                    multi_answers: inputs.multi_answers,
                    weight: Number(inputs.weight),
                    description: inputs.description,
                    question_mandatory: inputs.question_mandatory,
                    attachment_mandatory: inputs.attachment_mandatory,
                  },
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default AskGlobalQuestion;
