/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import RiskList from "./RiskList/RiskList";
import NewRisk from "./RiskList/NewRisk";
import NewRiskBlueprint from "./RiskBlueprints/NewRiskBlueprint";
import RiskBlueprints from "./RiskBlueprints/RiskBlueprints";
import Assessments from "./Assessments/Assessments";
import ThreatsVulns from "./ThreatsVulns/ThreatsVulns";
import { useERCStore } from "src/stores/erc";

const Risks = () => {
  const { selectedERCTab, setSelectedERCTab } = useERCStore();

  useEffect(() => {
    if (sessionStorage.selectedERCTab)
      setSelectedERCTab(sessionStorage.selectedERCTab);
    if (selectedERCTab === "") setSelectedERCTab("risk blueprints");
  }, []);

  return (
    <section className="flex flex-col flex-grow gap-5">
      <header className="flex items-center justify-between gap-10 text-b1-reg">
        <nav className="flex flex-wrap items-center">
          {["risk blueprints", "risks", "threats & vulnerabilities"].map(
            (tab) => {
              return (
                <button
                  key={tab}
                  className={`flex items-center gap-2 px-8 py-2 text-center capitalize ${
                    selectedERCTab === tab
                      ? "border-b-2 dark:text-blue-400 dark:border-blue-400"
                      : "dark:text-gray-500 dark:hover:text-white"
                  }`}
                  onClick={() => {
                    setSelectedERCTab(tab);
                    sessionStorage.selectedERCTab = tab;
                  }}
                >
                  {tab}
                  {tab === "assessments" && (
                    <img src="/general/beta.svg" alt="beta" />
                  )}
                </button>
              );
            }
          )}
        </nav>
        {selectedERCTab === "risk blueprints" ? (
          <NewRiskBlueprint />
        ) : selectedERCTab === "risks" ? (
          <NewRisk />
        ) : null}
      </header>
      {selectedERCTab === "risks" ? (
        <RiskList />
      ) : selectedERCTab === "risk blueprints" ? (
        <RiskBlueprints />
      ) : selectedERCTab === "threats & vulnerabilities" ? (
        <ThreatsVulns />
      ) : (
        <Assessments />
      )}
    </section>
  );
};

export default Risks;
