import React from "react";
import {
  convertToUTCShortString,
  getEmailFromUserID,
  getNamefromUserID,
} from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetAllUsers } from "../../../../../services/settings/users";
import { getCustomerID } from "../../../../../utils/general";

const EntityMetadata = ({ entity }: { entity: any }) => {
  const customerID = getCustomerID();

  const { data: allUsers } = GetAllUsers(customerID, false);

  const owner =
    allUsers?.find((user) => user.user_id === entity?.owner)?.user_email || "";

  return (
    <>
      {entity && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            <h4 className="text-t1-semi">Entity</h4>
            <header className="grid content-start gap-1">
              <h2 className="text-subt1-semi">{entity.name}</h2>
              <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                <h4>
                  Created by {getNamefromUserID(allUsers, entity.created_by)} (
                  {getEmailFromUserID(allUsers, entity.created_by)})
                </h4>
                <span>| {convertToUTCShortString(entity.created_at)}</span>
              </article>
              <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                <h4>
                  Last Updated by{" "}
                  {getNamefromUserID(allUsers, entity.last_updated_by)} (
                  {getEmailFromUserID(allUsers, entity.last_updated_by)})
                </h4>
                <span>| {convertToUTCShortString(entity.last_updated_at)}</span>
              </article>
            </header>
            <section className="flex flex-wrap items-center gap-2 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[entity.status?.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[entity.status?.toLowerCase()]
                  }`}
                >
                  {entity.status}
                </span>
              </article>
            </section>
            <p>{entity.description}</p>
            <section className="grid grid-cols-5 gap-x-20 gap-y-10">
              {owner && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Owner</h4>
                  <span className="text-b1-semi">{owner}</span>
                </article>
              )}
            </section>
          </section>
        </header>
      )}
    </>
  );
};

export default EntityMetadata;
