/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AssessmentCycleList from "./AssessmentCycles/AssessmentCycleList";
import Questions from "./Questions/Questions";

const Assessments = ({
  selectedTab,
  setSelectedTab,
  selectedQuestions,
  setSelectedQuestions,
  search,
}: {
  selectedTab: string;
  setSelectedTab: (selectedTab: string) => void;
  selectedQuestions: any[];
  setSelectedQuestions: (selectedQuestions: any[]) => void;
  search: any;
}) => {
  const [selectedType, setSelectedType] = useState<string>("");

  useEffect(() => {
    setSelectedType("assessments");
    setSelectedTab("assessments");
  }, []);

  return (
    <section className="flex flex-col flex-grow gap-5 w-full">
      <nav className="flex flex-wrap items-center">
        {["assessments", "questions"].map((tab) => {
          return (
            <button
              key={tab}
              className={`px-8 py-2 text-center capitalize ${
                selectedType === tab
                  ? "border-b-2 dark:text-blue-400 dark:border-blue-400"
                  : "dark:text-gray-500 dark:hover:text-white"
              }`}
              onClick={() => {
                setSelectedType(tab);
                if (tab === "assessments") setSelectedTab(tab);
              }}
            >
              {tab}
            </button>
          );
        })}
      </nav>
      {selectedType === "assessments" ? (
        <AssessmentCycleList setSelectedTab={setSelectedTab} />
      ) : (
        <Questions
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
          search={search}
        />
      )}
    </section>
  );
};

export default Assessments;
