import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Loader from "src/components/Loader/Loader";
import TableLayout from "src/layouts/TableLayout";
import { GetFrameworkChecklistQA } from "src/services/regulation-policy/framework";
import { copyToClipboard } from "src/utils/graph";

const Checklist = ({ documentID }: { documentID: string }) => {
  const [copied, setCopied] = useState<boolean>(false);

  const { data: questionnaire, status: questionnaireStatus } =
    GetFrameworkChecklistQA(documentID);
  return (
    <section className="grid gap-3">
      <button
        className="place-self-end small-blue-button"
        onClick={() => {
          setCopied(true);

          let copedText = "";
          questionnaire?.questionnaire?.forEach((question: string) => {
            copedText = `${copedText} \n• ${question}`;
          });
          copyToClipboard(copedText);
        }}
      >
        <FontAwesomeIcon icon={faCopy} />
        {copied ? "Copied" : "Copy"} All
      </button>
      {questionnaireStatus === "loading" ? (
        <Loader />
      ) : questionnaire?.questionnaire.length > 0 ? (
        <section className="flex flex-col flex-grow gap-5 pb-20">
          <TableLayout fullHeight>
            <thead className="sticky -top-1.5 dark:bg-gray-900">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                >
                  <article className="capitalize flex gap-10 justify-between">
                    <h4 className="break-words">Name</h4>
                  </article>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y dark:divide-gray-700">
              {questionnaire?.questionnaire?.map(
                (question: string, index: number) => {
                  return (
                    <tr
                      key={index}
                      data-test="table-row"
                      className="relative p-5 gap-3 break-words dark:bg-gray-800"
                    >
                      <td className="relative flex items-center gap-5 justify-between px-6 py-3 text-left break-words">
                        <p>{question}</p>
                        <button
                          className="place-self-end focus:text-blue-600"
                          onClick={() => copyToClipboard(question)}
                        >
                          <FontAwesomeIcon icon={faCopy} />
                        </button>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </TableLayout>
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/third-party-risk-placeholder.svg"
            alt="entities placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-t1-bold">Checklist</h4>
            <h4>No checklist available</h4>
          </article>
        </section>
      )}
    </section>
  );
};

export default Checklist;
