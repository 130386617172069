/* eslint-disable no-restricted-globals */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { KeyStringVal } from "src/types/general";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetRisks } from "src/services/erc/risks/risks";
import {
  AddRiskToVuln,
  GetRisksForVuln,
  RemoveRiskFromVuln,
} from "src/services/erc/risks/threats-vulns/vulns";
import { ChevronDownIcon } from "@heroicons/react/solid";

const AssociateRiskWithVulnFilter = ({ vulnID }: { vulnID: string }) => {
  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const { data: risksForVuln } = GetRisksForVuln(vulnID);
  const { data: risks } = GetRisks();
  const add = AddRiskToVuln();
  const remove = RemoveRiskFromVuln();

  const filteredItems = risks?.data.filter((item: KeyStringVal) =>
    item.name
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(query.toLowerCase().replace(/\s+/g, ""))
  );

  return (
    <section className="flex flex-col flex-grow gap-3">
      <article className="flex items-center gap-3 text-b2-reg">
        <header className="flex items-center gap-1 justify-self-start">
          <h4>Associated Risks</h4>
        </header>
        <article
          onMouseLeave={() => setShowDropdown(false)}
          onClick={() => setShowDropdown(true)}
          className="relative px-4 py-2 w-[10rem] h-9 dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 focus:outline-none rounded"
        >
          <article className="flex items-center justify-between gap-2">
            <input
              type="input"
              autoComplete="off"
              spellCheck="false"
              placeholder={`${risksForVuln?.length || 0} selected`}
              value={query}
              onChange={(e) => {
                if (!showDropdown) setShowDropdown(true);
                setQuery(e.target.value);
              }}
              className="w-full placeholder:normal-case capitalize border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
            />
            <span className="flex items-center pointer-events-none">
              <ChevronDownIcon
                className="w-5 h-5 dark:text-gray-500"
                aria-hidden="true"
              />
            </span>
          </article>
          {showDropdown && (
            <article className="absolute top-9 left-0 grid gap-1 p-1 content-start w-full max-h-36 dark:bg-gray-900 focus:outline-none shadow dark:shadow-black overflow-auto scrollbar rounded-b-sm z-10">
              {filteredItems?.map((risk: KeyStringVal, index: number) => {
                const included = risksForVuln?.some(
                  (member) => member.risk_id === risk.risk_id
                );

                return (
                  <button
                    key={index}
                    className="relative flex items-center gap-2 px-4 py-1 w-full break-words text-left dark:disabled:hover:bg-transparent dark:hover:bg-gray-600/70 duration-100"
                    onClick={() => {
                      if (included)
                        remove.mutate({
                          vulnID,
                          risk: [risk.risk_id],
                        });
                      else
                        add.mutate({
                          vulnID,
                          risk: [risk.risk_id],
                        });
                    }}
                  >
                    {included && (
                      <FontAwesomeIcon icon={faCheck} className="text-no" />
                    )}
                    <p>{risk.name}</p>
                  </button>
                );
              })}
            </article>
          )}
        </article>
      </article>
      {risksForVuln?.length > 0 && (
        <ul className="flex flex-wrap items-center gap-4 p-6 text-b1-reg dark:bg-gray-800 rounded-2xl">
          {risksForVuln?.map((risk: KeyStringVal) => {
            return (
              <li
                key={risk.risk_id}
                className="flex items-center gap-3 px-4 py-1 cursor-pointer dark:text-white dark:bg-blue-500 dark:hover:bg-blue-400/70 duration-100 rounded"
                onClick={() =>
                  navigate(`/erc/risks/details?risk_id=${risk.risk_id}`)
                }
              >
                <p>{risk.name}</p>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    remove.mutate({
                      vulnID,
                      risk: [risk.risk_id],
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="w-4 h-4 dark:hover:text-gray-500 duration-100 rounded"
                  />
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
};

export default AssociateRiskWithVulnFilter;
