import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import GeneralTemporalDatepicker from "../../../../components/Datepicker/GeneralTemporalDatepicker";
import RegularInput from "src/components/Input/RegularInput";
import { convertToMicrosec } from "../../../../utils/general";
import ToastLayout from "../../../../layouts/ToastLayout";
import AssessmentQuestionSetFilter from "src/components/Filter/ThirdPartyRisk/AssessmentQuestionSetFilter";
import MultiUserInput from "src/components/Input/MultiUserInput";
import EntityFilter from "src/components/Filter/ERC/EntityFilter";
import { AddAssessment } from "src/services/erc/assessments/assessments";

const NewAssessment = () => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    title: "",
    purpose: "",
    module: "ERC",
    object_type: "ENTITY",
    object_id: "",
    respondent_emails: [],
    question_set_id: "",
  });
  const [temporalStartDate, setTemporalStartDate] = useState<Date>(new Date());
  const [temporalEndDate, setTemporalEndDate] = useState<Date>(new Date());

  const addAssessment = AddAssessment();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addAssessment.reset();

  return (
    <>
      <button
        className="flex items-center gap-2 place-self-end px-4 py-2 text-base font-light dark:text-white dark:bg-blue-500 dark:hover:bg-blue-500/70 duration-100 rounded-xl"
        onClick={() => {
          setShow(true);
          setInputs({
            title: "",
            purpose: "",
            module: "ERC",
            object_type: "ENTITY",
            object_id: "",
            respondent_emails: [],
            question_set_id: "",
          });
          setTemporalStartDate(new Date());
          setTemporalEndDate(new Date());
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>New Assessment</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 mb-10">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Add New Assessment
          </h3>
          <RegularInput
            label="Name"
            keyName="title"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <GeneralTemporalDatepicker
            temporalStartDate={temporalStartDate}
            setTemporalStartDate={setTemporalStartDate}
            temporalEndDate={temporalEndDate}
            setTemporalEndDate={setTemporalEndDate}
            ignoreWarning
            required
          />
          <article className="grid grid-cols-2 gap-5">
            <RegularInput
              label="Purpose"
              keyName="purpose"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
            <EntityFilter
              label="Entity"
              keyName="object_id"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
            <MultiUserInput
              label="Respondent Emails"
              keyName="respondent_emails"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <AssessmentQuestionSetFilter
              label="Question Set"
              keyName="question_set_id"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
          </article>
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                inputs.title === "" ||
                Object.keys(inputs).length < 4 ||
                convertToMicrosec(temporalStartDate) ===
                  convertToMicrosec(temporalEndDate) ||
                inputs.purpose === "" ||
                inputs.object_id === ""
              }
              className="blue-button"
              onClick={() => {
                addAssessment.mutate({
                  info: {
                    start_date: convertToMicrosec(temporalStartDate),
                    end_date: convertToMicrosec(temporalEndDate),
                    title: inputs.title,
                    purpose: inputs.purpose,
                    module: inputs.module,
                    object_type: inputs.object_type,
                    object_id: inputs.object_id,
                    respondent_emails: inputs.respondent_emails,
                    question_set_id: inputs.question_set_id,
                  },
                });
                handleOnClose();
              }}
            >
              Done
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addAssessment.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New assessment has been created"
      />
    </>
  );
};

export default NewAssessment;
