import { useQuery, useMutation } from "react-query";
import { queryClient } from "src/App";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";

const prefix = "grc/risk-register/risks";

export const GetRisks = (
  pageNumber?: number,
  tags?: string[] | undefined,
  names?: string[],
  serialNumbers?: string[]
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-risks", pageNumber, tags, names, serialNumbers],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/getall`,
          pageNumber
            ? {
                page_number: pageNumber,
                page_size: pageSize,
                tags: tags,
                risk_name_list: names,
                risk_serial_number_list: serialNumbers,
              }
            : null,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const AddRisk = () =>
  useMutation<any, unknown, any, string>(
    async ({ info, signal }: { info: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(`/api/${apiVersion}/${prefix}`, info, {
          signal,
        });
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-risks"]),
    }
  );

export const EditRisk = (riskID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ info, signal }: { info: any; signal: AbortSignal }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${prefix}/${riskID}`,
          info,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-risk-metadata"]);
      },
    }
  );

export const GetRiskMetadata = (riskID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-risk-metadata", riskID],
    async ({ signal }) => {
      try {
        const res = await client.get(`/api/${apiVersion}/${prefix}/${riskID}`, {
          signal,
        });
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: riskID !== "",
      keepPreviousData: false,
    }
  );

export const AssignTagToRisk = (riskID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${riskID}/tag`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-risks"]);
        queryClient.invalidateQueries(["get-risk-metadata"]);
        queryClient.invalidateQueries(["get-tags-for-risk"]);
      },
    }
  );

export const RemoveTagFromRisk = (riskID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${riskID}/untag`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-risks"]);
        queryClient.invalidateQueries(["get-risk-metadata"]);
        queryClient.invalidateQueries(["get-tags-for-risk"]);
      },
    }
  );

export const GenerateTreatmentPlan = (riskID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${riskID}/treatment-plan`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-risk-metadata"]),
    }
  );

export const SearchThreat = () =>
  useMutation<any, unknown, any, string[]>(
    async ({
      threatName,
      signal,
    }: {
      threatName: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/grc/risk-register/search-threat`,
          { threat_name: threatName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const SearchVulnerability = () =>
  useMutation<any, unknown, any, string[]>(
    async ({
      vulnerabilityName,
      signal,
    }: {
      vulnerabilityName: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/grc/risk-register/search-vulnerability`,
          { vulnerability_name: vulnerabilityName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetVendorsForRisk = (riskID: string, pageNumber?: number) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-vendors-for-risk", riskID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${riskID}/vendors`,
          pageNumber
            ? {
                page_number: pageNumber,
                page_size: pageSize,
              }
            : null,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: riskID !== "",
      keepPreviousData: false,
    }
  );

export const AddVendorToRisk = () =>
  useMutation<any, unknown, any, string>(
    async ({
      riskID,
      vendor,
      signal,
    }: {
      riskID: string;
      vendor: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${riskID}/vendors/add`,
          vendor,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors-for-risk"]);
        queryClient.invalidateQueries(["get-risk-metadata"]);
        queryClient.invalidateQueries(["get-vendors"]);
      },
    }
  );

export const RemoveVendorFromRisk = () =>
  useMutation<any, unknown, any, string>(
    async ({
      riskID,
      vendor,
      signal,
    }: {
      riskID: string;
      vendor: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${riskID}/vendors/remove`,
          vendor,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors-for-risk"]);
        queryClient.invalidateQueries(["get-risk-metadata"]);
        queryClient.invalidateQueries(["get-vendors"]);
      },
    }
  );

export const SearchRiskName = () =>
  useMutation<any, unknown, any, string[]>(
    async ({ riskName, signal }: { riskName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/grc/risk-register/search-risk`,
          { risk_name: riskName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const SearchRiskID = () =>
  useMutation<any, unknown, any, string[]>(
    async ({
      serialNumber,
      signal,
    }: {
      serialNumber: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/grc/risk-register/search-risk-by-serial-number`,
          { serial_number: serialNumber },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const RestoreRisk = (riskID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${riskID}/restore`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-risk-metadata"]),
    }
  );

export const ArchiveRisk = (riskID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${riskID}/archive`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-risk-metadata"]),
    }
  );
