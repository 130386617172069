import { useQuery, useMutation } from "react-query";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { queryClient } from "src/App";

const prefix = "grc/risk-register/vulnerabilities";

export const GetVulnerabilities = (
  pageNumber?: number,
  tags?: string[] | undefined,
  groups?: string[]
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-vulnerabilities", pageNumber, tags, groups],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/getall`,
          pageNumber
            ? {
                pager: {
                  page_number: pageNumber,
                  page_size: pageSize,
                },
              }
            : null,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const AddVulnerability = () =>
  useMutation<any, unknown, any, string>(
    async ({ info, signal }: { info: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(`/api/${apiVersion}/${prefix}`, info, {
          signal,
        });
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-vulnerabilities"]),
    }
  );

export const EditVulnerability = (vulnerabilityID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ info, signal }: { info: any; signal: AbortSignal }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${prefix}/${vulnerabilityID}`,
          info,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vulnerability-metadata"]);
      },
    }
  );

export const GetVulnerabilityMetadata = (vulnerabilityID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-vulnerability-metadata", vulnerabilityID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${vulnerabilityID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vulnerabilityID !== "",
      keepPreviousData: false,
    }
  );

export const GetRisksForVuln = (vulnID: string, pageNumber?: number) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-risks-for-vuln", vulnID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vulnID}/risks`,
          pageNumber
            ? {
                page_number: pageNumber,
                page_size: pageSize,
              }
            : null,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vulnID !== "",
      keepPreviousData: false,
    }
  );

export const AddRiskToVuln = () =>
  useMutation<any, unknown, any, string>(
    async ({
      vulnID,
      risk,
      signal,
    }: {
      vulnID: string;
      risk: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vulnID}/risks/add`,
          risk,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-risks-for-vuln"]);
        queryClient.invalidateQueries(["get-vuln-metadata"]);
        queryClient.invalidateQueries(["get-risks"]);
      },
    }
  );

export const RemoveRiskFromVuln = () =>
  useMutation<any, unknown, any, string>(
    async ({
      vulnID,
      risk,
      signal,
    }: {
      vulnID: string;
      risk: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vulnID}/risks/remove`,
          risk,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-risks-for-vuln"]);
        queryClient.invalidateQueries(["get-vuln-metadata"]);
        queryClient.invalidateQueries(["get-risks"]);
      },
    }
  );

export const RestoreVuln = (vulnID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vulnID}/restore`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-vuln-metadata"]),
    }
  );

export const ArchiveVuln = (vulnID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${vulnID}/archive`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-vuln-metadata"]),
    }
  );
