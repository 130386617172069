import { useQuery, useMutation } from "react-query";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { queryClient } from "src/App";

const prefix = "grc/risk-register/threats";

export const GetThreats = (
  pageNumber?: number,
  tags?: string[] | undefined,
  groups?: string[]
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-threats", pageNumber, tags, groups],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/getall`,
          pageNumber
            ? {
                pager: {
                  page_number: pageNumber,
                  page_size: pageSize,
                },
              }
            : null,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const AddThreat = () =>
  useMutation<any, unknown, any, string>(
    async ({ info, signal }: { info: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(`/api/${apiVersion}/${prefix}`, info, {
          signal,
        });
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-threats"]),
    }
  );

export const EditThreat = (threatID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ info, signal }: { info: any; signal: AbortSignal }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${prefix}/${threatID}`,
          info,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-threat-metadata"]);
      },
    }
  );

export const GetThreatMetadata = (threatID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-threat-metadata", threatID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${threatID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: threatID !== "",
      keepPreviousData: false,
    }
  );

export const GetRisksForThreat = (threatID: string, pageNumber?: number) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-risks-for-threat", threatID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${threatID}/risks`,
          pageNumber
            ? {
                page_number: pageNumber,
                page_size: pageSize,
              }
            : null,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: threatID !== "",
      keepPreviousData: false,
    }
  );

export const AddRiskToThreat = () =>
  useMutation<any, unknown, any, string>(
    async ({
      threatID,
      risk,
      signal,
    }: {
      threatID: string;
      risk: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${threatID}/risks/add`,
          risk,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-risks-for-threat"]);
        queryClient.invalidateQueries(["get-threat-metadata"]);
        queryClient.invalidateQueries(["get-risks"]);
      },
    }
  );

export const RemoveRiskFromThreat = () =>
  useMutation<any, unknown, any, string>(
    async ({
      threatID,
      risk,
      signal,
    }: {
      threatID: string;
      risk: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${threatID}/risks/remove`,
          risk,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-risks-for-threat"]);
        queryClient.invalidateQueries(["get-threat-metadata"]);
        queryClient.invalidateQueries(["get-risks"]);
      },
    }
  );

export const RestoreThreat = (threatID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${threatID}/restore`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-threat-metadata"]),
    }
  );

export const ArchiveThreat = (threatID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${threatID}/archive`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-threat-metadata"]),
    }
  );
