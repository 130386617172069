/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import RegularInput from "src/components/Input/RegularInput";
import CategoryInput from "../../../../components/Input/CategoryInput";
import { AddEntity } from "../../../../services/erc/entities/entities";
import ToastLayout from "../../../../layouts/ToastLayout";
import UserInput from "src/components/Input/UserInput";
import EntityTypeFilter from "src/components/Filter/ERC/EntityTypeFilter";
import { Menu, Transition } from "@headlessui/react";
import { GetBuiltinEntityTypes } from "src/services/erc/entities/entity-types";
import { KeyStringVal } from "src/types/general";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import { SearchCountry } from "src/services/grc";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VendorFilter from "src/components/Filter/ERC/VendorFilter";

const NewEntity = () => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: "",
    description: "",
    external_reference_id: "",
    owner: null,
    owner_text: null,
    status: "ACTIVE",
    entity_type: null,
    provider_id: null,
    provider_id_text: null,
    managed_by: null,
    managed_by_text: null,
  });
  const [selectedEntityType, setSelectedEntityType] = useState<string>("");
  const [valid, setValid] = useState<boolean>(true);

  const { data: builtInEntityTypes } = GetBuiltinEntityTypes();
  const countries = SearchCountry();
  const addEntity = AddEntity();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addEntity.reset();

  useEffect(() => {
    countries.mutate({});
  }, []);

  return (
    <>
      <Menu as="article" className="relative inline-block text-left">
        <Menu.Button
          className="place-self-end blue-button"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedEntityType("");
            setInputs({
              name: "",
              description: "",
              external_reference_id: "",
              owner: null,
              owner_text: null,
              status: "ACTIVE",
              entity_type: null,
              provider_id: null,
              provider_id_text: null,
              managed_by: null,
              managed_by_text: null,
            });
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
          <h4>Add Entity</h4>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 top-12 grid w-full gap-2 origin-top-right focus:outline-none dark:text-white">
            <nav className="grid content-start w-full h-full dark:bg-blue-600 rounded">
              {builtInEntityTypes?.map((entityType: KeyStringVal) => {
                return (
                  <button
                    key={entityType.entity_type_id}
                    className="flex items-center gap-2 px-4 py-2 text-left dark:bg-blue-600 dark:hover:bg-blue-500/70 duration-100 rounded"
                    onClick={(e) => {
                      e.stopPropagation();

                      setShow(true);
                      switch (entityType.name) {
                        case "Application":
                          setSelectedEntityType(entityType.name);
                          setInputs({
                            ...inputs,
                            entity_type: entityType.entity_type_id,
                            internal_or_3rd_party: "",
                            hosting_locations: "",
                            storage_format: "",
                            hosting_type: "",
                            hosting_provider: "",
                            technical_security_measures: "",
                            organizational_security_measures: "",
                            other_security_measures: "",
                            data_retention: "",
                            criticality: "",
                            security_classification: "",
                            asset_type: "",
                            next_review_date: Date.now() * 1000,
                          });
                          break;
                        case "DATABASE":
                          setSelectedEntityType(entityType.name);
                          setInputs({
                            ...inputs,
                            entity_type: entityType.entity_type_id,
                            internal_or_3rd_party: "",
                            hosting_locations: "",
                            storage_format: "",
                            hosting_type: "",
                            hosting_provider: "",
                            technical_security_measures: "",
                            organizational_security_measures: "",
                            other_security_measures: "",
                            data_retention: "",
                            criticality: "",
                            security_classification: "",
                            asset_type: "",
                            next_review_date: Date.now() * 1000,
                          });
                          break;
                      }
                    }}
                  >
                    <p>{entityType.name}</p>
                  </button>
                );
              })}
              <button
                className="flex items-center gap-2 px-4 py-2 text-left dark:bg-blue-600 dark:hover:bg-blue-500/70 duration-100 border-t-1 dark:border-gray-400 rounded"
                onClick={(e) => {
                  e.stopPropagation();
                  setShow(true);
                }}
              >
                <p>New</p>
              </button>
            </nav>
          </Menu.Items>
        </Transition>
      </Menu>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 w-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Add Entity</h3>
          <section className="grid md:grid-cols-2 gap-5">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <CategoryInput
              label="Status"
              keyName="status"
              list={["DRAFT", "INACTIVE", "ACTIVE", "ARCHIVED"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <UserInput
              label="Owner"
              keyName="owner"
              inputs={inputs}
              setInputs={setInputs}
              vertical
              allowNewValue
            />
            <RegularInput
              label="External Reference Id"
              keyName="external_reference_id"
              inputs={inputs}
              setInputs={setInputs}
            />
            <EntityTypeFilter
              label="Entity Type"
              keyName="entity_type"
              inputs={inputs}
              setInputs={setInputs}
            />
            <VendorFilter
              label="Provider"
              keyName="provider_id"
              inputs={inputs}
              setInputs={setInputs}
            />
            <UserInput
              label="Managed By"
              keyName="managed_by"
              inputs={inputs}
              setInputs={setInputs}
              vertical
              allowNewValue
            />
            <RegularInput
              label="Description"
              keyName="description"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              textarea
            />
            {["Application", "Database"].includes(selectedEntityType) && (
              <>
                <CategoryInput
                  label="Internal or 3rd Party"
                  keyName="internal_or_3rd_party"
                  list={["Internal", "3rd Party"]}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <CategoryInput
                  label="Hosting Locations"
                  keyName="hosting_locations"
                  list={countries.data?.data}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <CategoryInput
                  label="Storage Format"
                  keyName="storage_format"
                  list={[
                    "Backup Tapes",
                    "Cloud Storage",
                    "Data Store",
                    "FTP Server",
                    "File Cabinet",
                    "File Warehouse",
                    "Portable Media",
                    "Shared Drive",
                    "Web Server",
                  ]}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <CategoryInput
                  label="Hosting Type"
                  keyName="hosting_type"
                  list={["Cloud", "On Premise"]}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <CategoryInput
                  label="Hosting Provider"
                  keyName="hosting_provider"
                  list={[
                    "Akamai",
                    "Amazon Web Services",
                    "Atlantic.Net",
                    "CloudSigma",
                    "CloudStitch",
                    "Digital Ocean Cloud",
                    "Google Cloud",
                    "HP Helion Eucalyptus",
                    "Heroku",
                    "IBM SoftLayer Cloud",
                    "Joyent",
                    "Kyup",
                    "Linode Cloud",
                  ]}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <CategoryInput
                  label="Technical Security Measures"
                  keyName="technical_security_measures"
                  list={[
                    "Access Control Lists",
                    "Anonymization",
                    "Anti-Malware",
                    "Breach Detection Tools",
                    "Data Backup",
                    "Encryption",
                    "Firewalls",
                    "Intrusion Detection Tools",
                    "Logging",
                    "Logical Access Control",
                    "Mobile Device Management (MDM) Tools",
                    "Multi-Factor Authentication",
                    "Network Authentication",
                    "Pseudonymization",
                    "Regular Software Updates",
                    "Vulnerability Detection Tools",
                  ]}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <CategoryInput
                  label="Organizational Security Measures"
                  keyName="organizational_security_measures"
                  list={[
                    "Acceptable Use Policies",
                    "Access Reviews",
                    "Awareness and Training",
                    "Background Checks",
                    "Business Continuity Plans",
                    "Data Processing Agreements",
                    "Disaster Recovery Plans",
                    "Incident Management Plans",
                    "Need-To-Know Restrictions",
                    "Non-Disclosure Agreements",
                    "Password Policies",
                    "Penetration Tests",
                    "Regular Test Plan",
                    "Secure Disposal",
                    "Secure Premises",
                    "Segmented Access Control",
                    "Supervision",
                    "Surveillance",
                    "Tabletop Exercises",
                    "Vendor Assessments",
                  ]}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <RegularInput
                  label="Other Security Measures"
                  keyName="other_security_measures"
                  inputs={inputs}
                  setInputs={setInputs}
                />
                <RegularInput
                  label="Data Retention"
                  keyName="data_retention"
                  inputs={inputs}
                  setInputs={setInputs}
                />
                <CategoryInput
                  label="Criticality"
                  keyName="criticality"
                  list={["Partner", "Critical", "High", "Medium", "Low"]}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <CategoryInput
                  label="Security Classification"
                  keyName="security_classification"
                  list={[
                    "Sensitive/Regulated",
                    "Restricted",
                    "Confidential",
                    "Public",
                    "Internal",
                  ]}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <CategoryInput
                  label="Asset Type"
                  keyName="asset_type"
                  list={["Tangible", "In tangible"]}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <GeneralSnapshotDatepicker
                  label="Next Review Date"
                  keyName="next_review_date"
                  inputs={inputs}
                  setInputs={setInputs}
                />
              </>
            )}
          </section>
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                if (inputs.owner_text) delete inputs.owner;
                if (inputs.provider_id_text) delete inputs.provider_id;
                if (inputs.managed_by_text) delete inputs.managed_by;

                if (["Application", "Database"].includes(selectedEntityType))
                  addEntity.mutate({
                    info: {
                      grc_entity_create: {
                        name: inputs.name,
                        description: inputs.description,
                        external_reference_id: inputs.external_reference_id,
                        owner: inputs.owner,
                        owner_text: inputs.owner_text,
                        status: inputs.status,
                        ...(inputs.entity_type && {
                          entity_type: inputs.entity_type,
                        }),
                        provider_id: inputs.provider_id,
                        provider_id_text: inputs.provider_id_text,
                        managed_by: inputs.managed_by,
                        managed_by_text: inputs.managed_by_text,
                      },
                      additional_properties: {
                        internal_or_3rd_party: inputs.internal_or_3rd_party,
                        hosting_locations: inputs.hosting_locations,
                        storage_format: inputs.storage_format,
                        hosting_type: inputs.hosting_type,
                        hosting_provider: inputs.hosting_provider,
                        technical_security_measures:
                          inputs.technical_security_measures,
                        organizational_security_measures:
                          inputs.organizational_security_measures,
                        other_security_measures: inputs.other_security_measures,
                        data_retention: inputs.data_retention,
                        criticality: inputs.criticality,
                        security_classification: inputs.security_classification,
                        asset_type: inputs.asset_type,
                        next_review_date: inputs.next_review_date,
                      },
                    },
                  });
                else
                  addEntity.mutate({
                    info: {
                      grc_entity_create: inputs,
                    },
                  });

                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addEntity.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New entity has been created"
      />
    </>
  );
};

export default NewEntity;
