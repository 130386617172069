import React, { useState } from "react";
import RegularInput from "src/components/Input/RegularInput";
import { policyLifecycleStates } from "src/constants/grc";
import ModalLayout from "src/layouts/ModalLayout";
import {
  GetPolicyLifecycleEvents,
  GetPolicyLifecycleState,
  UpdatePolicyLifecycleState,
} from "src/services/regulation-policy/policy";
import { GetAllUsers } from "src/services/settings/users";
import {
  convertToUTCString,
  getCustomerID,
  getEmailFromUserID,
  sortNumericData,
} from "src/utils/general";

const Lifecycle = ({
  policyID,
  policyVersionID,
}: {
  policyID: string;
  policyVersionID: string;
}) => {
  const customerID = getCustomerID();

  const [show, setShow] = useState<string>("");
  const [inputs, setInputs] = useState<any>({
    notes: "",
  });

  const { data: allUsers } = GetAllUsers(customerID, false);
  const { data: policyState } = GetPolicyLifecycleState(
    policyID,
    policyVersionID
  );
  const { data: policyLifecycleEvents } = GetPolicyLifecycleEvents(
    policyID,
    policyVersionID
  );
  const updateTransitionState = UpdatePolicyLifecycleState(policyID);

  const curStateIdx = policyLifecycleStates.findIndex(
    (state) => state === policyState?.state
  );

  const handleOnClose = () => {
    setShow("");
    setInputs({ notes: "" });
  };

  const handleGoBack = (stateIdx: number) => {
    const state = stateIdx === 1 ? "DRAFT" : "IN_REVIEW";
    updateTransitionState.mutate({
      info: {
        policy_id: policyID,
        version_id: policyVersionID,
        state_to: state,
        notes: inputs.notes,
      },
    });
    handleOnClose();
  };

  const handleGoForward = (stateIdx: number) => {
    const state =
      stateIdx === 0
        ? "IN_REVIEW"
        : stateIdx === 1
        ? "AWAITING_APPROVAL"
        : stateIdx === 2
        ? "PUBLISHED"
        : "RETIRED";
    updateTransitionState.mutate({
      info: {
        policy_id: policyID,
        version_id: policyVersionID,
        state_to: state,
        notes: inputs.notes,
      },
    });
    handleOnClose();
  };

  return (
    <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
      <h4 className="text-t1-semi">Lifecycle Flow</h4>
      <ol className="items-start sm:flex w-full">
        {policyLifecycleStates?.map((state, stateIdx: number) => {
          const events = policyLifecycleEvents?.filter(
            (event) => event.state === state
          );
          const sortedEvents = sortNumericData(
            events,
            "state_change_date",
            "desc"
          );
          return (
            <li key={stateIdx} className="relative mb-6 sm:mb-0 w-full">
              <article className="flex items-center">
                <button
                  disabled={
                    stateIdx < curStateIdx ||
                    stateIdx >= curStateIdx + 1 ||
                    curStateIdx === 4
                  }
                  className={`z-10 flex items-center justify-center w-32 h-32 bg-blue-100 rounded-full ring-0 ring-white ${
                    curStateIdx !== 4 && stateIdx === curStateIdx
                      ? "dark:bg-blue-900"
                      : stateIdx < curStateIdx || curStateIdx === 4
                      ? "dark:bg-green-500"
                      : "dark:bg-gray-700"
                  } sm:ring-8 dark:ring-gray-900 shrink-0`}
                  onClick={() => {
                    setShow(state);
                    setInputs({ notes: "" });
                  }}
                >
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    {state?.replaceAll("_", " ")}
                  </h3>
                </button>
                <article className="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></article>
                <ModalLayout showModal={show === state} onClose={handleOnClose}>
                  <section className="grid gap-5">
                    <h3 className="flex items-center gap-2 text-t1-semi">
                      Update State
                    </h3>
                    <RegularInput
                      label="Notes"
                      keyName="notes"
                      inputs={inputs}
                      setInputs={setInputs}
                      textarea
                    />
                    <article className="flex items-center place-content-center gap-5">
                      {[1, 2].includes(stateIdx) && (
                        <button
                          className="red-button"
                          onClick={() => handleGoBack(stateIdx)}
                        >
                          {stateIdx === 1 ? "Needs Update" : "Rejected"}
                        </button>
                      )}
                      <button
                        className="blue-button"
                        onClick={() => handleGoForward(stateIdx)}
                      >
                        {stateIdx === 0
                          ? "Request Review"
                          : stateIdx === 1
                          ? "Review Complete"
                          : stateIdx === 2
                          ? "Approved"
                          : "Retire"}
                      </button>
                    </article>
                  </section>
                </ModalLayout>
              </article>
              <article className="mt-3 sm:pe-8">
                <section className="flex flex-col flex-grow gap-2">
                  {sortedEvents?.map((event: any, index: number) => {
                    return (
                      <section
                        key={index}
                        className="grid gap-2 px-6 py-4 text-b2-reg dark:bg-gray-800 rounded-lg"
                      >
                        <header className="flex flex-col flex-grow gap-1">
                          <article className="flex items-center gap-2 px-2 py-1 dark:bg-blue-700 rounded">
                            <span>
                              {convertToUTCString(event.state_change_date)}
                            </span>
                          </article>
                          <article className="flex items-center gap-1 text-b2-reg dark:text-gray-400">
                            {getEmailFromUserID(
                              allUsers,
                              event.state_change_by
                            )}
                          </article>
                        </header>
                        <p className="text-gray-500 dark:text-gray-400">
                          {event.notes}
                        </p>
                      </section>
                    );
                  })}
                </section>
              </article>
            </li>
          );
        })}
      </ol>
    </section>
  );
};

export default Lifecycle;
