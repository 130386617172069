import React, { useState } from "react";
import { GetAllUsers } from "src/services/settings/users";
import { KeyStringVal } from "src/types/general";
import {
  convertToUTCShortString,
  getCustomerID,
  getEmailFromUserID,
} from "src/utils/general";

const MetadataField = ({
  metadata,
  label,
  keyName,
  singleUser,
  multipleUsers,
  isDate,
  isList,
}: {
  metadata: any;
  label: string;
  keyName: string;
  singleUser?: boolean;
  multipleUsers?: boolean;
  isDate?: boolean;
  isList?: boolean;
}) => {
  const customerID = getCustomerID();

  const [showMore, setShowMore] = useState<boolean>(false);

  const { data: allUsers } = GetAllUsers(customerID, false);

  return (
    <>
      {metadata && metadata[keyName] ? (
        (((isList || multipleUsers) && metadata[keyName]?.length > 0) ||
          (!(isList || multipleUsers) && metadata[keyName])) && (
          <article className="grid content-start gap-1">
            <h4 className="dark:text-gray-300 text-b2-reg">{label}</h4>
            {singleUser ? (
              <span className="text-b1-semi">
                {getEmailFromUserID(allUsers, metadata[keyName])}
              </span>
            ) : multipleUsers ? (
              <article className="flex flex-col flex-grow gap-1">
                {metadata[keyName].map((user: KeyStringVal) => {
                  return (
                    <span key={user.user_id} className="text-b1-semi">
                      {getEmailFromUserID(allUsers, user.user_id)}
                    </span>
                  );
                })}
              </article>
            ) : isDate ? (
              <span className="text-b1-semi">
                {convertToUTCShortString(metadata[keyName])}
              </span>
            ) : isList ? (
              <ul className="flex flex-col flex-grow gap-1">
                {metadata[keyName].map((item: string) => (
                  <span key={item} className="text-b1-semi">
                    {item}
                  </span>
                ))}
              </ul>
            ) : (
              <article className="flex flex-wrap items-center gap-2 break-all text-b1-semi">
                {String(metadata[keyName]).slice(
                  0,
                  showMore ? metadata[keyName].length : 200
                )}
                {metadata[keyName].length > 200 && !showMore && "..."}
                {metadata[keyName].length > 200 && (
                  <button
                    onClick={() => setShowMore(!showMore)}
                    className="dark:text-blue-500 dark:hover:text-blue-500/70 duration-100"
                  >
                    show {showMore ? "less" : "more"}
                  </button>
                )}
              </article>
            )}
          </article>
        )
      ) : (
        <article className="grid content-start gap-1">
          <h4 className="dark:text-gray-300 text-b2-reg">{label}</h4>
          <span className="text-b1-semi">-</span>
        </article>
      )}
    </>
  );
};

export default MetadataField;
