import React from "react";
import {
  convertToUTCString,
  getEmailFromUserID,
  getNamefromUserID,
} from "src/utils/general";
import { GetControlMetadata } from "../../../../../services/erc/controls/controls";
import UpdateControl from "./UpdateControl";
import { GetAllUsers } from "../../../../../services/settings/users";
import { getCustomerID } from "../../../../../utils/general";
import {
  attributeColors,
  attributeTextColors,
} from "../../../../../constants/general";
import { GetControlBlueprints } from "../../../../../services/erc/controls/control-blueprints";
import { GetEntities } from "../../../../../services/erc/entities/entities";
import ControlTags from "./ControlTags";
import ControlBlueprintMetadata from "./ControlBlueprintMetadata";
import EntityMetadata from "./EntityMetadata";
import MetadataField from "src/components/ERC/MetadataField";

const ControlMetadata = ({ controlID }: { controlID: string }) => {
  const customerID = getCustomerID();

  const { data: controlMetadata } = GetControlMetadata(controlID);
  const { data: allUsers } = GetAllUsers(customerID, false);
  const { data: entities } = GetEntities();
  const { data: controlBlueprints } = GetControlBlueprints();

  const entity =
    entities?.data?.find(
      (entity) => entity.grc_entity_id === controlMetadata?.grc_entity_id
    )?.name || "";
  const controlBlueprint =
    controlBlueprints?.data?.find(
      (blueprint) =>
        blueprint.control_blueprint_id === controlMetadata?.control_blueprint_id
    )?.name || "";

  return (
    <>
      {controlMetadata && (
        <header className="grid gap-5">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-5">
              <article className="grid content-start gap-1">
                <h2 className="text-subt1-semi">{controlMetadata.name}</h2>
                <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                  <h4>
                    Last Updated by{" "}
                    {getNamefromUserID(
                      allUsers,
                      controlMetadata.last_updated_by
                    )}{" "}
                    (
                    {getEmailFromUserID(
                      allUsers,
                      controlMetadata.last_updated_by
                    )}
                    )
                  </h4>
                  <span>
                    | {convertToUTCString(controlMetadata.last_updated_at)}
                  </span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    Created by{" "}
                    {getNamefromUserID(allUsers, controlMetadata.created_by)} (
                    {getEmailFromUserID(allUsers, controlMetadata.created_by)})
                  </h4>
                  <span>
                    | {convertToUTCString(controlMetadata.created_at)}
                  </span>
                </article>
              </article>
              {/* <UpdateControl controlID={controlID} control={controlMetadata} /> */}
            </header>
            <section className="flex flex-wrap items-center gap-2 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[controlMetadata.state.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[controlMetadata.state.toLowerCase()]
                  }`}
                >
                  {controlMetadata.state}
                </span>
              </article>
              {controlMetadata.same_as_entity_owner && (
                <article
                  className={`flex items-center gap-1 ${attributeColors.true}`}
                >
                  Same as Entity
                </article>
              )}
            </section>
            <section className="grid grid-cols-5 gap-x-20 gap-y-10">
              <MetadataField
                metadata={controlMetadata}
                label="Control Reference Id"
                keyName="control_reference_id"
              />
              {entity && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Entity</h4>
                  <span className="text-b1-semi">{entity}</span>
                </article>
              )}
              {controlBlueprint && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Control Blueprint
                  </h4>
                  <span className="text-b1-semi">{controlBlueprint}</span>
                </article>
              )}
              <MetadataField
                metadata={controlMetadata}
                label="Owner"
                keyName="owner"
                singleUser
              />
              <MetadataField
                metadata={controlMetadata}
                label="Reviewer"
                keyName="reviewer"
                singleUser
              />
            </section>
          </section>
          <ControlBlueprintMetadata
            blueprint={controlMetadata.control_blueprint}
          />
          <EntityMetadata entity={controlMetadata.grc_entity} />
          <ControlTags controlID={controlID} tags={controlMetadata.tags} />
        </header>
      )}
    </>
  );
};

export default ControlMetadata;
