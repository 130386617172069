import React, { Fragment, useState } from "react";
import TableLayout from "../../../../../../layouts/TableLayout";
import { attributeColors } from "../../../../../../constants/general";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { faDownload, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteDocument from "./DeleteDocument";
import VendorResponseList from "./VendorResponseList/VendorResponseList";
import { KeyStringVal } from "../../../../../../types/general";
import { sortRows, handleSort } from "../../../../../../utils/general";
import ModalLayout from "../../../../../../layouts/ModalLayout";
import { GetDocumentMetrics } from "src/services/third-party-risk/vendors/vendors";
import {
  ResponsiveContainer,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
} from "recharts";
import CustomMetricTooltip from "src/components/Chart/Tooltip/CustomMetricTooltip";
import { ThreeDots } from "react-loader-spinner";

const OtherDocumentList = ({
  vendorID,
  assessmentID,
  filteredDocumentList,
  selectedDocumentType,
}: {
  vendorID: string;
  assessmentID: string;
  filteredDocumentList: any;
  selectedDocumentType: string;
}) => {
  const [selectedFile, setSelectedFile] = useState<string>("");
  const [sort, setSort] = useState<KeyStringVal>({
    direction: "asc",
    order_by: "filename",
  });

  const { data: documentMetrics, status: metricStatus } = GetDocumentMetrics(
    vendorID,
    assessmentID
  );

  const headers =
    assessmentID && selectedDocumentType === "Questionnaire Responses"
      ? ["filename", "status", "metrics"]
      : ["filename", "status"];
  const sortedRows =
    filteredDocumentList && sortRows(filteredDocumentList, sort);

  const handleOnClose = () => setSelectedFile("");

  return (
    <TableLayout fullHeight>
      <thead className="sticky -top-1.5 dark:bg-gray-900">
        <tr>
          {headers.map((col: string) => {
            return (
              <th
                scope="col"
                key={col}
                className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
              >
                <article className="capitalize flex gap-10 justify-between">
                  <h4 className="break-words">
                    {col === "file" ? "Document Name" : col}
                  </h4>
                  {col !== "metrics" && (
                    <button aria-label="sort">
                      <FontAwesomeIcon
                        icon={faSort}
                        className="mt-0.5 dark:text-gray-700"
                        onClick={() => handleSort(col, setSort)}
                      />
                    </button>
                  )}
                </article>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="divide-y dark:divide-gray-700">
        {sortedRows?.map((row: any, index: number) => {
          const fileType = row.download_uri.slice(
            row.download_uri.lastIndexOf(".") + 1,
            row.download_uri.indexOf("?")
          );
          const data = documentMetrics
            ? [documentMetrics[row.document_id]]
            : [];
          return (
            <Fragment key={index}>
              <tr
                data-test="table-row"
                className="relative p-5 gap-3 break-words dark:bg-gray-800"
              >
                {headers.map((col: string, colIndex: number) => {
                  return (
                    <td
                      key={`${index}-${colIndex}`}
                      className="relative px-6 py-3 last:pr-20 text-left break-words"
                    >
                      {col === "filename" ? (
                        <button
                          className="underline text-left break-all dark:text-blue-400 dark:hover:text-blue-400/70 duration-100"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedFile(row.document_id);
                          }}
                        >
                          {row.filename}
                        </button>
                      ) : col === "status" ? (
                        <p
                          className={`${
                            attributeColors[String(row[col]).toLowerCase()]
                          }`}
                        >
                          {String(row[col]).replaceAll("_", " ")}
                        </p>
                      ) : metricStatus === "loading" ? (
                        <ThreeDots
                          visible={true}
                          height="20"
                          width="50"
                          ariaLabel="dna-loading"
                          wrapperStyle={{}}
                          wrapperClass="dna-wrapper"
                        />
                      ) : (
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart
                            data={data}
                            layout="vertical"
                            margin={{
                              left: -60,
                            }}
                          >
                            <XAxis
                              type="number"
                              axisLine={false}
                              display="none"
                            />
                            <YAxis
                              type="category"
                              axisLine={false}
                              display="none"
                            />
                            <Tooltip
                              cursor={{ fill: "#23394F", fillOpacity: 0.4 }}
                              content={<CustomMetricTooltip data={data} />}
                            />
                            <Bar dataKey="yes" stackId="a" fill="green" />
                            <Bar dataKey="no" stackId="a" fill="red" />
                            <Bar dataKey="maybe" stackId="a" fill="yellow" />
                            <Bar
                              dataKey="no_answer"
                              stackId="a"
                              fill="purple"
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      )}
                      {colIndex === headers.length - 1 && (
                        <article className="absolute top-1/3 right-5 flex items-center gap-5">
                          <button
                            className="dark:hover:text-blue-500/60 duration-100"
                            onClick={() => {
                              let a = document.createElement("a");
                              a.href = row.download_uri;
                              a.target = "_blank";
                              a.click();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="text-blue-500"
                            />{" "}
                          </button>
                          <DeleteDocument
                            vendorID={vendorID}
                            assessmentID={assessmentID}
                            documentID={row.document_id}
                          />
                        </article>
                      )}
                    </td>
                  );
                })}
              </tr>
              <ModalLayout
                showModal={selectedFile === row.document_id}
                onClose={handleOnClose}
              >
                <section className="flex flex-col flex-grow gap-5">
                  <h4 className="text-t1-semi">{row.filename}</h4>
                  {row.document_type === "Questionnaire Responses" ? (
                    <VendorResponseList
                      vendorID={vendorID}
                      assessmentID={assessmentID}
                      documentID={row.document_id}
                    />
                  ) : (
                    <DocViewer
                      documents={[
                        {
                          uri: row.download_uri,
                          fileType: fileType,
                        },
                      ]}
                      pluginRenderers={DocViewerRenderers}
                      config={{
                        header: {
                          disableHeader: false,
                          disableFileName: true,
                          retainURLParams: false,
                        },
                      }}
                    />
                  )}
                </section>
              </ModalLayout>
            </Fragment>
          );
        })}
      </tbody>
    </TableLayout>
  );
};

export default OtherDocumentList;
