import React from "react";
import { convertToUTCShortString } from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetVendorMetadata } from "src/services/third-party-risk/vendors/vendors";
import UpdateVendor from "../UpdateVendor";
import VendorTags from "../VendorTags";
import VendorGroupFilter from "../../../../../components/Filter/ThirdPartyRisk/VendorGroupFilter";
import Status from "./Status";
import SendEmail from "./SendEmail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { Disclosure } from "@headlessui/react";
import ExportResponses from "./DocumentList/VendorResponseList/ExportResponses";
import MetadataField from "src/components/ERC/MetadataField";
import NewAssessment from "./NewAssessment";
import InherentCheck from "./InherentCheck";

const VendorMetadata = ({
  vendorID,
  assessmentID,
}: {
  vendorID: string;
  assessmentID: string | undefined;
}) => {
  const hasAssessment = localStorage.assessmentCycle && assessmentID;

  const { data: vendorMetadata } = GetVendorMetadata(vendorID, assessmentID);

  return (
    <>
      {vendorMetadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            {hasAssessment && (
              <h4>Assessment: {localStorage.assessmentCycle}</h4>
            )}
            <header className="flex items-start justify-between gap-5">
              <article className="grid content-start gap-1">
                <article className="flex items-center gap-5">
                  {vendorMetadata.logo_uri && (
                    <img
                      src={vendorMetadata.logo_uri}
                      alt="logo"
                      className="w-10 h-10 rounded-full"
                    />
                  )}
                  <h2 className="text-subt1-semi">{vendorMetadata.name}</h2>
                  <VendorGroupFilter
                    label="Vendor Group"
                    vendor={vendorMetadata}
                  />
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>last update at</h4>
                  <span>
                    {convertToUTCShortString(vendorMetadata.last_updated)}
                  </span>
                </article>
              </article>
              <article className="flex items-center gap-5">
                {!hasAssessment && (
                  <>
                    <InherentCheck vendorID={vendorID} />
                    <NewAssessment vendorID={vendorID} />
                  </>
                )}
                {hasAssessment &&
                  vendorMetadata.expiry_time >= 0 &&
                  vendorMetadata?.status !== "CLOSED" && (
                    <SendEmail
                      vendorID={vendorID}
                      assessmentID={assessmentID}
                      vendor={vendorMetadata}
                    />
                  )}
                <Status vendorID={vendorID} active={vendorMetadata.active} />
                {hasAssessment && (
                  <ExportResponses
                    vendorID={vendorID}
                    assessmentID={assessmentID}
                  />
                )}
                <UpdateVendor vendorID={vendorID} vendor={vendorMetadata} />
              </article>
            </header>
            <section className="flex flex-wrap items-center justify-between gap-10 text-b1-reg">
              <article className="flex flex-wrap items-center gap-2">
                <article
                  className={`flex items-center gap-1 ${
                    attributeColors[vendorMetadata.status.toLowerCase()]
                  }`}
                >
                  Status
                  <span
                    className={`capitalize ${
                      attributeTextColors[vendorMetadata.status.toLowerCase()]
                    }`}
                  >
                    {vendorMetadata.status.toLowerCase()}
                  </span>
                </article>
              </article>
              {vendorMetadata.email_sent && vendorMetadata.expiry_time >= 0 && (
                <span>
                  <FontAwesomeIcon
                    icon={faWarning}
                    className="text-yellow-500"
                  />{" "}
                  {vendorMetadata.expiry_time <= 24
                    ? `${vendorMetadata.expiry_time} hours`
                    : `${Math.round(
                        vendorMetadata.expiry_time / 24
                      )} days`}{" "}
                  left for response
                </span>
              )}
            </section>
            <MetadataField
              metadata={vendorMetadata}
              label="Description"
              keyName="description"
            />
            <Disclosure defaultOpen>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">
                      Primary Vendor Information
                    </h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-3 content-start gap-5 w-full">
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Vendor Type"
                        keyName="vendor_type"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Assessed for AI Functionality"
                        keyName="assessed_for_ai_functionality"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Website"
                        keyName="website"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Address"
                        keyName="address"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Number of Employees"
                        keyName="number_of_employees"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Ref-ID"
                        keyName="ref_id"
                      />
                      {vendorMetadata.inherent_risk && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Inherent Risk
                          </h4>
                          <span
                            className={`capitalize ${
                              attributeColors[
                                vendorMetadata.inherent_risk?.toLowerCase()
                              ]
                            } ${
                              attributeTextColors[vendorMetadata.inherent_risk]
                            }`}
                          >
                            {vendorMetadata.inherent_risk}
                          </span>
                        </article>
                      )}
                      {vendorMetadata.rank_tier && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Rank Tier
                          </h4>
                          <span
                            className={`capitalize ${
                              attributeColors[
                                vendorMetadata.rank_tier?.toLowerCase()
                              ]
                            } ${attributeTextColors[vendorMetadata.rank_tier]}`}
                          >
                            {vendorMetadata.rank_tier}
                          </span>
                        </article>
                      )}
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Is Publicly Traded"
                        keyName="is_publicly_traded"
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">Risk Management</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-3 gap-5">
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Application Owner Email"
                        keyName="primary_app_service"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Vendor Manager"
                        keyName="vendor_manager"
                        singleUser
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Help Ticket"
                        keyName="help_ticket"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Sponsor"
                        keyName="sponsor"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Criticality"
                        keyName="criticality"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Data Classification"
                        keyName="data_classification"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Last Review Date"
                        keyName="last_review_date"
                        isDate
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Next Review Date"
                        keyName="next_review_date"
                        isDate
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Reassessment Frequency"
                        keyName="reassessment_frequency"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Notes"
                        keyName="notes"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Additional Notes"
                        keyName="additional_notes"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Analyst Comments"
                        keyName="analyst_comments"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Vendor Review Checklist"
                        keyName="review_checklist"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Additional Information"
                        keyName="additional_information"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Impacted Systems"
                        keyName="impacted_systems"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="SSO Enabled"
                        keyName="sso_enabled"
                      />
                      {vendorMetadata.vendor_tier && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Vendor Tier
                          </h4>
                          <span
                            className={`capitalize ${
                              attributeColors[
                                vendorMetadata.vendor_tier?.toLowerCase()
                              ]
                            } ${
                              attributeTextColors[vendorMetadata.vendor_tier]
                            }`}
                          >
                            {vendorMetadata.vendor_tier}
                          </span>
                        </article>
                      )}
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Business Associate Agreement Executed"
                        keyName="baa_executed"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="External Document URL"
                        keyName="external_document_url"
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">
                      Vendor Contact Information
                    </h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-3 gap-5">
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Contact Name"
                        keyName="contact_name"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Contact Email"
                        keyName="contact_email"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Contact Phone"
                        keyName="contact_phone"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Location"
                        keyName="location"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Data Host Location"
                        keyName="data_host_location"
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">Scoping Details</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-3 gap-5">
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Target Primary Application Service"
                        keyName="target_primary_application_service"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Authentication Protocol"
                        keyName="authentication_protocol"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="PHI"
                        keyName="is_PHI"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="PII"
                        keyName="is_PII"
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">Others</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-3 gap-5">
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Workflow"
                        keyName="workflow"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Dow Jones Nth Party Risks"
                        keyName="dj_n_party_risks"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Internal Email CC List"
                        keyName="internal_email_list"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Name of Business User"
                        keyName="business_user_name"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Head of Department"
                        keyName="department_head"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Department"
                        keyName="department"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Stage"
                        keyName="stage"
                      />
                      <MetadataField
                        metadata={vendorMetadata}
                        label="Historical Data"
                        keyName="historical_data"
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
          </section>
          <section className="p-6 dark:bg-gray-800 rounded-2xl">
            <VendorTags vendorID={vendorID} tags={vendorMetadata.tags} />
          </section>
        </header>
      )}
    </>
  );
};

export default VendorMetadata;
