import React from "react";
import {
  convertToUTCString,
  getCustomerID,
  getEmailFromUserID,
  getNamefromUserID,
} from "src/utils/general";
import { GetRiskBlueprintMetadata } from "../../../../../services/erc/risks/risk-blueprints";
import ArchiveRestoreRiskBlueprint from "./ArchiveRestoreRiskBlueprint";
import UpdateRiskBlueprint from "./UpdateRiskBlueprint";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetAllUsers } from "src/services/settings/users";
import MetadataField from "src/components/ERC/MetadataField";

const RiskBlueprintMetadata = ({
  riskBlueprintID,
}: {
  riskBlueprintID: string;
}) => {
  const customerID = getCustomerID();

  const { data: riskBlueprintMetadata } =
    GetRiskBlueprintMetadata(riskBlueprintID);
  const { data: allUsers } = GetAllUsers(customerID, false);

  return (
    <>
      {riskBlueprintMetadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-5">
              <article className="grid content-start gap-1">
                <h2 className="text-subt1-semi">
                  {riskBlueprintMetadata.name}
                </h2>
                <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                  <h4>
                    Last Updated by{" "}
                    {getNamefromUserID(
                      allUsers,
                      riskBlueprintMetadata.last_updated_by
                    )}{" "}
                    (
                    {getEmailFromUserID(
                      allUsers,
                      riskBlueprintMetadata.last_updated_by
                    )}
                    )
                  </h4>
                  <span>
                    |{" "}
                    {convertToUTCString(riskBlueprintMetadata.last_updated_at)}
                  </span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    Created by{" "}
                    {getNamefromUserID(
                      allUsers,
                      riskBlueprintMetadata.created_by
                    )}{" "}
                    (
                    {getEmailFromUserID(
                      allUsers,
                      riskBlueprintMetadata.created_by
                    )}
                    )
                  </h4>
                  <span>
                    | {convertToUTCString(riskBlueprintMetadata.created_at)}
                  </span>
                </article>
              </article>
              {/* <article className="flex items-center gap-5">
                <UpdateRiskBlueprint
                  riskBlueprintID={riskBlueprintID}
                  blueprint={riskBlueprintMetadata}
                />
                <ArchiveRestoreRiskBlueprint
                  riskBlueprintID={riskBlueprintID}
                  blueprint={riskBlueprintMetadata}
                />
              </article> */}
            </header>
            <section className="flex flex-wrap items-center gap-5 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[riskBlueprintMetadata.status.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[
                      riskBlueprintMetadata.status.toLowerCase()
                    ]
                  }`}
                >
                  {riskBlueprintMetadata.status}
                </span>
              </article>
            </section>
            <MetadataField
              metadata={riskBlueprintMetadata}
              label="Description"
              keyName="description"
            />
            <section className="grid grid-cols-5 gap-x-20 gap-y-10">
              <MetadataField
                metadata={riskBlueprintMetadata}
                label="Type"
                keyName="type"
              />
              <MetadataField
                metadata={riskBlueprintMetadata}
                label="Classification"
                keyName="classification"
              />
              <MetadataField
                metadata={riskBlueprintMetadata}
                label="Source Name"
                keyName="source_name"
              />
              <MetadataField
                metadata={riskBlueprintMetadata}
                label="Source Id"
                keyName="source_id"
              />
              <MetadataField
                metadata={riskBlueprintMetadata}
                label="Category"
                keyName="category"
              />
              <MetadataField
                metadata={riskBlueprintMetadata}
                label="Subcategory"
                keyName="sub_category"
              />
              <MetadataField
                metadata={riskBlueprintMetadata}
                label="Threat"
                keyName="threat"
              />
              <MetadataField
                metadata={riskBlueprintMetadata}
                label="Vulnerability"
                keyName="vulnerability"
              />
              <MetadataField
                metadata={riskBlueprintMetadata}
                label="Owners"
                keyName="owners"
                multipleUsers
              />
              <MetadataField
                metadata={riskBlueprintMetadata}
                label="Reviewers"
                keyName="reviewers"
                multipleUsers
              />
              <MetadataField
                metadata={riskBlueprintMetadata}
                label="Approvers"
                keyName="approvers"
                multipleUsers
              />
              <MetadataField
                metadata={riskBlueprintMetadata}
                label="Treatment Type"
                keyName="treatment_type"
              />
              <MetadataField
                metadata={riskBlueprintMetadata}
                label="Treatment Plan"
                keyName="treatment_plan"
              />
              <MetadataField
                metadata={riskBlueprintMetadata}
                label="Deadline"
                keyName="deadline"
                isDate
              />
              <MetadataField
                metadata={riskBlueprintMetadata}
                label="Create Risks Automatically"
                keyName="create_risks_automatically"
              />
              <MetadataField
                metadata={riskBlueprintMetadata}
                label="Compensating Controls"
                keyName="compensating_controls"
              />
              <MetadataField
                metadata={riskBlueprintMetadata}
                label="Additional Information"
                keyName="additional_information"
              />
            </section>
          </section>
        </header>
      )}
    </>
  );
};

export default RiskBlueprintMetadata;
