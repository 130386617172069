/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import GeneralTemporalDatepicker from "src/components/Datepicker/GeneralTemporalDatepicker";
import RegularInput from "src/components/Input/RegularInput";
import ToastLayout from "src/layouts/ToastLayout";
import { convertToDate, convertToMicrosec } from "src/utils/general";
import AssessmentQuestionSetFilter from "src/components/Filter/ThirdPartyRisk/AssessmentQuestionSetFilter";
import {
  GetInherentCheck,
  PreAssessmentCheck,
} from "src/services/third-party-risk/vendors/vendors";
import UserInput from "src/components/Input/UserInput";

const InherentCheck = ({ vendorID }: { vendorID: string }) => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    title: "",
    purpose: null,
    vendor_mgr_email: "",
    third_party_id: "",
    question_set_id: null,
  });
  const [temporalStartDate, setTemporalStartDate] = useState<Date>(new Date());
  const [temporalEndDate, setTemporalEndDate] = useState<Date>(new Date());

  const { data: inherentCheck } = GetInherentCheck(vendorID);
  const check = PreAssessmentCheck(vendorID);

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => check.reset();

  useEffect(() => {
    if (inherentCheck) {
      setInputs({
        title: inherentCheck.title,
        purpose: inherentCheck.purpose,
        vendor_mgr_email: inherentCheck.vendor_mgr_email,
        third_party_id: inherentCheck.third_party_id,
        question_set_id: inherentCheck.question_set_id,
      });
      setTemporalStartDate(convertToDate(inherentCheck.start_date));
      setTemporalEndDate(convertToDate(inherentCheck.end_date));
    }
  }, [inherentCheck]);

  return (
    <>
      <button
        className="flex items-center gap-2 place-self-end px-4 py-2 text-base font-light dark:text-white dark:bg-blue-500 dark:hover:bg-blue-500/70 duration-100 rounded-xl"
        onClick={() => setShow(true)}
      >
        <h4>Inherent pre-Assessment Check</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 mb-10">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Vendor Inherent pre-Assessment Check
          </h3>
          <RegularInput
            label="Name"
            keyName="title"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <GeneralTemporalDatepicker
            temporalStartDate={temporalStartDate}
            setTemporalStartDate={setTemporalStartDate}
            temporalEndDate={temporalEndDate}
            setTemporalEndDate={setTemporalEndDate}
            ignoreWarning
            required
          />
          <RegularInput
            label="Purpose"
            keyName="purpose"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <article className="grid grid-cols-2 gap-5">
            <UserInput
              label="Vendor Manager"
              keyName="vendor_mgr_email"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <AssessmentQuestionSetFilter
              label="Question Set"
              keyName="question_set_id"
              inputs={inputs}
              setInputs={setInputs}
            />
          </article>
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                Object.values(inputs).includes("") ||
                inputs.purpose === "" ||
                convertToMicrosec(temporalStartDate) ===
                  convertToMicrosec(temporalEndDate)
              }
              className="blue-button"
              onClick={() => {
                check.mutate({
                  info: {
                    start_date: convertToMicrosec(temporalStartDate),
                    end_date: convertToMicrosec(temporalEndDate),
                    title: inputs.title,
                    purpose: inputs.purpose,
                    vendor_mgr_email: inputs.vendor_mgr_email,
                    third_party_id: inputs.third_party_id,
                    question_set_id: inputs.question_set_id,
                  },
                });
                handleOnClose();
              }}
            >
              pre-Assessment Check
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={check.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="pre-Assessment Checked"
      />
    </>
  );
};

export default InherentCheck;
