import React from "react";
import DropdownLayout from "src/layouts/DropdownLayout";

const PolicyGroupFilter = ({
  selectedPolicyGroups,
  setSelectedPolicyGroups,
  setSelectedPolicyIDs,
  policyGroups,
  index,
}: {
  selectedPolicyGroups: any;
  setSelectedPolicyGroups: any;
  setSelectedPolicyIDs: (selectedPolicyIDs: string[]) => void;
  policyGroups: any;
  index: number;
}) => {
  return (
    <DropdownLayout
      label="Policy Group"
      value={selectedPolicyGroups[index]?.title}
      placeholder="Select"
      width="w-4/5"
    >
      {policyGroups?.map((policyGroup: any, policyGroupIdx: number) => {
        return (
          <button
            key={policyGroupIdx}
            className="relative flex items-center gap-2 px-4 py-1 w-full break-words text-left dark:hover:bg-gray-600/70 duration-100"
            onClick={() => {
              const newPolicyGroups = selectedPolicyGroups;
              newPolicyGroups.splice(index, 1, policyGroup);
              setSelectedPolicyGroups([...newPolicyGroups]);
              setSelectedPolicyIDs([]);
            }}
          >
            <p>{policyGroup.title}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default PolicyGroupFilter;
