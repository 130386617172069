import React, { useState } from "react";
import ReturnPage from "src/components/Button/ReturnPage";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import EntityMetadata from "./EntityMetadata";
import DocumentList from "./DocumentList/DocumentList";
import QnA from "./QnA/QnA";
import AssessmentMetadata from "./AssessmentMetadata";
import RiskList from "./RiskList";

const Assessment = () => {
  const parsed = parseURL();

  const [selectedTab, setSelectedTab] = useState<string>("Documents");

  const assessmentID = String(parsed.assessment_id) || "";
  const entityID = String(parsed.entity_id) || "";

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-5 px-5 pt-5 h-full w-full overflow-auto scrollbar">
        <article className="flex items-center gap-2">
          <ReturnPage />
          <h4 className="flex items-center gap-2 text-b3-reg">
            <span className="dark:text-gray-500">
              Entities, Risks, & Controls
            </span>
            <span>/</span>
            <span>Assessment</span>
          </h4>
        </article>
        <AssessmentMetadata assessmentID={assessmentID} entityID={entityID} />
        <EntityMetadata entityID={entityID} />
        <RiskList assessmentID={assessmentID} entityID={entityID} />
        <nav className="flex flex-wrap items-center gap-1 p-1 w-max text-b1-reg dark:bg-gray-900 rounded-lg">
          {["Documents"].map((tab) => {
            return (
              <button
                key={tab}
                className={`px-4 py-1 text-center capitalize ${
                  selectedTab === tab
                    ? "dark:text-white dark:bg-gray-800"
                    : "dark:text-gray-500 dark:hover:bg-gray-500/30 duration-100"
                } rounded-lg`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab}
              </button>
            );
          })}
        </nav>
        {selectedTab === "Documents" ? (
          <DocumentList entityID={entityID} assessmentID={assessmentID} />
        ) : (
          <QnA entityID={entityID} assessmentID={assessmentID} />
        )}
      </main>
    </PageLayout>
  );
};

export default Assessment;
