import React from "react";
import {
  convertToUTCString,
  getCustomerID,
  getEmailFromUserID,
  getNamefromUserID,
} from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import UpdateEntity from "./UpdateEntity";
import EntityTags from "./EntityTags";
import { GetEntityMetadata } from "../../../../../services/erc/entities/entities";
import ArchiveRestoreEntity from "./ArchiveRestoreEntity";
import { GetAllUsers } from "src/services/settings/users";
import MetadataField from "src/components/ERC/MetadataField";
import NewAssessment from "./NewAssessment";

const EntityMetadata = ({ entityID }: { entityID: string }) => {
  const customerID = getCustomerID();

  const { data: entityMetadata } = GetEntityMetadata(entityID);
  const { data: allUsers } = GetAllUsers(customerID, false);

  return (
    <>
      {entityMetadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-5">
              <article className="grid content-start gap-1">
                <h2 className="text-subt1-semi">{entityMetadata.name}</h2>
                <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                  <h4>
                    Last Updated by{" "}
                    {getNamefromUserID(
                      allUsers,
                      entityMetadata.last_updated_by
                    )}{" "}
                    (
                    {getEmailFromUserID(
                      allUsers,
                      entityMetadata.last_updated_by
                    )}
                    )
                  </h4>
                  <span>
                    | {convertToUTCString(entityMetadata.last_updated_at)}
                  </span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    Created by{" "}
                    {getNamefromUserID(allUsers, entityMetadata.created_by)} (
                    {getEmailFromUserID(allUsers, entityMetadata.created_by)})
                  </h4>
                  <span>| {convertToUTCString(entityMetadata.created_at)}</span>
                </article>
              </article>
              <article className="flex items-center gap-5">
                <NewAssessment entityID={entityID} />
                {/* <UpdateEntity entityID={entityID} entity={entityMetadata} /> */}
                {/* <ArchiveRestoreEntity
                  entityID={entityID}
                  entity={entityMetadata}
                /> */}
              </article>
            </header>
            <section className="flex flex-wrap items-center gap-5 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[entityMetadata.status?.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[entityMetadata.status?.toLowerCase()]
                  }`}
                >
                  {entityMetadata.status}
                </span>
              </article>
            </section>
            <MetadataField
              metadata={entityMetadata}
              label="Description"
              keyName="description"
            />
            <section className="grid grid-cols-5 gap-x-20 gap-y-10">
              {entityMetadata?.entity_type?.name && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Entity Type
                  </h4>
                  <span className="text-b1-semi">
                    {entityMetadata?.entity_type?.name}
                  </span>
                </article>
              )}
              <MetadataField
                metadata={entityMetadata}
                label="External Reference Id"
                keyName="external_reference_id"
              />
              <MetadataField
                metadata={entityMetadata}
                label="Owner"
                keyName="owner"
                singleUser
              />
              {["APPLICATION", "DATABASE"].includes(
                entityMetadata?.entity_type?.name
              ) && (
                <>
                  <MetadataField
                    metadata={entityMetadata}
                    label="Managed By"
                    keyName="managed_by"
                    singleUser
                  />
                  <MetadataField
                    metadata={entityMetadata?.additional_properties}
                    label="Internal or 3rd Party"
                    keyName="internal_or_3rd_party"
                  />
                  <MetadataField
                    metadata={entityMetadata?.additional_properties}
                    label="Hosting Locations"
                    keyName="hosting_locations"
                  />
                  <MetadataField
                    metadata={entityMetadata?.additional_properties}
                    label="Storage Format"
                    keyName="storage_format"
                  />
                  <MetadataField
                    metadata={entityMetadata?.additional_properties}
                    label="Hosting Type"
                    keyName="hosting_type"
                  />
                  <MetadataField
                    metadata={entityMetadata?.additional_properties}
                    label="Hosting Provider"
                    keyName="hosting_provider"
                  />
                  <MetadataField
                    metadata={entityMetadata?.additional_properties}
                    label="Technical Security Measures"
                    keyName="technical_security_measures"
                  />
                  <MetadataField
                    metadata={entityMetadata?.additional_properties}
                    label="Organizational Security Measures"
                    keyName="organizational_security_measures"
                  />
                  <MetadataField
                    metadata={entityMetadata?.additional_properties}
                    label="Other Security Measures"
                    keyName="other_security_measures"
                  />
                  <MetadataField
                    metadata={entityMetadata?.additional_properties}
                    label="Data Retention"
                    keyName="data_retention"
                  />
                  <MetadataField
                    metadata={entityMetadata?.additional_properties}
                    label="Criticality"
                    keyName="criticality"
                  />
                  <MetadataField
                    metadata={entityMetadata?.additional_properties}
                    label="Security Classification"
                    keyName="security_classification"
                  />
                  <MetadataField
                    metadata={entityMetadata?.additional_properties}
                    label="Asset Type"
                    keyName="asset_type"
                  />
                  <MetadataField
                    metadata={entityMetadata?.additional_properties}
                    label="Next Review Date"
                    keyName="next_review_date"
                    isDate
                  />
                </>
              )}
            </section>
          </section>
          <EntityTags entityID={entityID} tags={entityMetadata.tags} />
        </header>
      )}
    </>
  );
};

export default EntityMetadata;
