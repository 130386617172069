import React, { useState } from "react";
import RegularInput from "src/components/Input/RegularInput";
import { AddCustomQuestionSet } from "src/services/third-party-risk/questions/custom-question-sets";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import TableLayout from "../../../../../layouts/TableLayout";
import { KeyStringVal } from "src/types/general";
import TempResponse from "./TempResponse";

const CreateManualSet = ({
  setShow,
  inputs,
  setInputs,
  selectedQuestions,
  setSelectedQuestions,
  metadata,
  filteredMetadata,
}: {
  setShow: (show: boolean) => void;
  inputs: any;
  setInputs: (inputs: any) => void;
  selectedQuestions: any[];
  setSelectedQuestions: (selectedQuestions: any[]) => void;
  metadata: any;
  filteredMetadata: any;
}) => {
  const [activeItem, setActiveItem] = useState<any>({});

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });
  const sensors = useSensors(pointerSensor);

  const createCustomQuestionSet = AddCustomQuestionSet();

  const handleOnClose = () => {
    setSelectedQuestions([]);
    setShow(false);
  };

  const reorderQuestionsList = (e: DragEndEvent) => {
    if (!e.over) return;

    if (e.active.id !== e.over.id) {
      const oldIdx = selectedQuestions.findIndex(
        (response: KeyStringVal) => response.generated_id === e.active.id
      );
      const newIdx = selectedQuestions.findIndex(
        (response: KeyStringVal) => response.generated_id === e.over!.id
      );
      setSelectedQuestions(arrayMove(selectedQuestions, oldIdx, newIdx));
    }
  };

  return (
    <section className="grid content-start gap-5 p-4 h-full dark:bg-gray-700 rounded-xl">
      <h3 className="flex items-center gap-2 text-t1-semi">
        Create Question Set
      </h3>
      <RegularInput
        label="Name"
        keyName="name"
        inputs={inputs}
        setInputs={setInputs}
        required
      />
      <RegularInput
        label="Description"
        keyName="description"
        inputs={inputs}
        setInputs={setInputs}
      />
      <DndContext
        sensors={sensors}
        onDragStart={(e) =>
          setActiveItem(
            selectedQuestions.find(
              (item: KeyStringVal) => item.generated_id === e.active.id
            )
          )
        }
        onDragEnd={reorderQuestionsList}
      >
        <TableLayout>
          <thead className="sticky -top-1.5 dark:bg-gray-900">
            <tr>
              {filteredMetadata?.map((col: string) => {
                const column = metadata.properties[col];

                if (col === "tags") return null;

                return (
                  <th
                    scope="col"
                    key={col}
                    className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                  >
                    <article className="capitalize flex gap-10 justify-between">
                      <h4 className="break-words">{column.title}</h4>
                    </article>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="divide-y dark:divide-gray-700">
            <SortableContext items={selectedQuestions}>
              {selectedQuestions?.map((qa: any) => {
                return (
                  <TempResponse
                    key={qa.generated_id}
                    qa={qa}
                    selectedQuestions={selectedQuestions}
                    setSelectedQuestions={setSelectedQuestions}
                    filteredMetadata={filteredMetadata}
                  />
                );
              })}
            </SortableContext>
          </tbody>
        </TableLayout>

        <DragOverlay
          dropAnimation={{
            duration: 150,
            easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
          }}
        >
          {activeItem ? (
            <TempResponse
              qa={activeItem}
              selectedQuestions={selectedQuestions}
              setSelectedQuestions={setSelectedQuestions}
              filteredMetadata={filteredMetadata}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
      <article className="flex items-center place-content-end gap-5">
        <button className="black-button" onClick={handleOnClose}>
          Cancel
        </button>
        <button
          disabled={inputs.name === ""}
          className="blue-button"
          onClick={() => {
            let questionIDs = {};
            selectedQuestions.forEach((curQ, idx) => {
              questionIDs = {
                ...questionIDs,
                [curQ.generated_id]: {
                  order_key: idx,
                  response: curQ.response,
                  multi_answers: curQ.multi_answers,
                  question_mandatory: curQ.question_mandatory,
                  attachment_mandatory: curQ.attachment_mandatory,
                },
              };
            });
            createCustomQuestionSet.mutate({
              info: {
                name: inputs.name,
                description: inputs.description,
                question_ids: questionIDs,
              },
            });
            handleOnClose();
          }}
        >
          <h4>Done</h4>
        </button>
      </article>
    </section>
  );
};

export default CreateManualSet;
