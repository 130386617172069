import React from "react";
import { KeyStringVal } from "src/types/general";
import Response from "./Response";

const GlobalQuestionsSearchResults = ({
  searchData,
  selectedQuestions,
  setSelectedQuestions,
}: {
  searchData: any;
  selectedQuestions: any[];
  setSelectedQuestions: (selectedQuestions: any[]) => void;
}) => {
  const resultCount = searchData?.length || 0;

  return (
    <section className="flex flex-col flex-grow gap-5 overflow-auto scrollbar">
      <span>
        {resultCount} result{resultCount !== 1 && "s"} found
      </span>
      <ul className="flex flex-col flex-grow gap-5 pb-20">
        {searchData?.map((qa: KeyStringVal) => {
          return (
            <Response
              key={qa.generated_id}
              qa={qa}
              selectedQuestions={selectedQuestions}
              setSelectedQuestions={setSelectedQuestions}
            />
          );
        })}
      </ul>
    </section>
  );
};

export default GlobalQuestionsSearchResults;
