import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import { AddControl } from "../../../../services/erc/controls/controls";
import ToastLayout from "../../../../layouts/ToastLayout";
import UserInput from "../../../../components/Input/UserInput";
import EntityFilter from "../../../../components/Filter/ERC/EntityFilter";
import ControlBlueprintFilter from "../../../../components/Filter/ERC/ControlBlueprintFilter";
import CheckboxInput from "src/components/Input/CheckboxInput";

const NewControl = () => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: "",
    control_reference_id: "",
    reviewer: null,
    same_as_entity_owner: false,
    grc_entity_id: "",
    control_blueprint_id: null,
    owner: null,
  });
  const [valid, setValid] = useState<boolean>(true);

  const addControl = AddControl();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addControl.reset();

  return (
    <>
      <button
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            control_reference_id: "",
            reviewer: null,
            same_as_entity_owner: false,
            grc_entity_id: "",
            control_blueprint_id: null,
            owner: null,
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Control</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 pb-20 w-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Add Control</h3>
          <section className="grid md:grid-cols-2 gap-5">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <EntityFilter
              label="Entity"
              keyName="grc_entity_id"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
            <ControlBlueprintFilter
              label="Control Blueprint"
              keyName="control_blueprint_id"
              inputs={inputs}
              setInputs={setInputs}
            />
            <UserInput
              label="Reviewer"
              keyName="reviewer"
              inputs={inputs}
              setInputs={setInputs}
              vertical
            />
            <RegularInput
              label="Control Reference Id"
              keyName="control_reference_id"
              inputs={inputs}
              setInputs={setInputs}
            />
          </section>
          <section className="grid grid-cols-2 gap-5">
            {!inputs.same_as_entity_owner && (
              <UserInput
                label="Owner"
                keyName="owner"
                inputs={inputs}
                setInputs={setInputs}
                vertical
              />
            )}
          </section>
          <CheckboxInput
            label="Same as entity owner"
            keyName="same_as_entity_owner"
            inputs={inputs}
            setInputs={setInputs}
          />
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === "" || inputs.grc_entity_id === ""}
              className="blue-button"
              onClick={() => {
                addControl.mutate({
                  info: inputs,
                });

                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addControl.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New control has been created"
      />
    </>
  );
};

export default NewControl;
