/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import NewEntityType from "./EntityTypes/NewEntityType";
import EntityTypes from "./EntityTypes/EntityTypes";
import EntityList from "./EntityList/EntityList";
import { useERCStore } from "src/stores/erc";
import NewEntity from "./EntityList/NewEntity";

const Entities = () => {
  const { selectedERCTab, setSelectedERCTab } = useERCStore();

  useEffect(() => {
    if (sessionStorage.selectedERCTab)
      setSelectedERCTab(sessionStorage.selectedERCTab);
    if (selectedERCTab === "") setSelectedERCTab("entity types");
  }, []);

  return (
    <section className="flex flex-col flex-grow gap-5">
      <header className="flex items-center justify-between gap-10 text-b1-reg">
        <nav className="flex flex-wrap items-center">
          {["entity types", "entities"].map((tab) => {
            return (
              <button
                key={tab}
                className={`px-8 py-2 text-center capitalize ${
                  selectedERCTab === tab
                    ? "border-b-2 dark:text-blue-400 dark:border-blue-400"
                    : "dark:text-gray-500 dark:hover:text-white"
                }`}
                onClick={() => {
                  setSelectedERCTab(tab);
                  sessionStorage.selectedERCTab = tab;
                }}
              >
                {tab}
              </button>
            );
          })}
        </nav>
        {selectedERCTab === "entity types" ? (
          <NewEntityType />
        ) : (
          <article className="flex flex-row flex-grow place-content-end gap-5">
            <NewEntity />
          </article>
        )}
      </header>
      {selectedERCTab === "entity types" ? (
        <EntityTypes />
      ) : selectedERCTab === "entities" ? (
        <EntityList />
      ) : null}
    </section>
  );
};

export default Entities;
