/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { motion } from "framer-motion";
import { pageMapping } from "../../constants/general";
import { useNavigate } from "react-router-dom";
import Help from "./Help";
import Profile from "./Profile";
import Notification from "./Notification";
import EnvFilter from "../Filter/General/EnvFilter";
import SpotlightSearch from "./SpotlightSearch";
import Vuln from "./Vuln";
import { GetEnabledModules } from "src/services/general/general";
import { decodeJWT } from "src/utils/general";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationPin } from "@fortawesome/free-solid-svg-icons";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const jwt = decodeJWT();

  const { data: enabledModules } = GetEnabledModules();

  return (
    <header className="flex items-center justify-between py-3 px-4 dark:text-white border-0 border-transparent dark:bg-main">
      <article className="flex items-start gap-8">
        <button
          onClick={() =>
            navigate(
              enabledModules?.includes("EXTRA")
                ? `/getting-started`
                : "/regulation-policy/summary"
            )
          }
          className="flex items-center gap-10"
        >
          <img
            src="/general/logos/uno-id.svg"
            alt="unoai logo"
            className="w-7 h-7"
          />
        </button>
        <motion.h4 className="mt-1 text-lg">
          {pageMapping[sessionStorage.page]}
        </motion.h4>
        <EnvFilter />
      </article>

      <section className="grid gap-3 -mb-3">
        <article className="flex gap-3">
          {/* {enabledModules && enabledModules.EXTRA === "enabled" && (
          <SpotlightSearch />
        )} */}
          {/* <Help /> */}
          <Profile />
          <Notification />
          <article className="flex items-center place-self-end gap-2 pb-2 dark:text-gray-400">
            <FontAwesomeIcon icon={faLocationPin} />
            <p className="capitalize dark:text-gray-400 font-extralight">
              {jwt?.scope.customer_name}
            </p>
          </article>
        </article>
      </section>
    </header>
  );
};

export default Header;
