import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import CategoryInput from "src/components/Input/CategoryInput";
import ToastLayout from "src/layouts/ToastLayout";
import { AddThreat } from "src/services/erc/risks/threats-vulns/threats";
import RefIDFilter from "src/components/Filter/ERC/RefIDFilter";

const NewThreat = () => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: "",
    description: "",
    category: "",
    sub_category: "",
    status: "DRAFT",
    ref_ids: {},
    framework_or_standard: "",
  });
  const [valid, setValid] = useState<boolean>(true);

  const addRisk = AddThreat();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addRisk.reset();

  return (
    <>
      <button
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            description: "",
            category: "",
            sub_category: "",
            status: "DRAFT",
            ref_ids: {},
            framework_or_standard: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Threat</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 w-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Add Threat</h3>
          <section className="grid md:grid-cols-2 gap-5">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <RegularInput
              label="Category"
              keyName="category"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Subcategory"
              keyName="sub_category"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CategoryInput
              label="Status"
              keyName="status"
              list={["DRAFT", "INACTIVE", "ACTIVE", "ARCHIVED"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <RegularInput
              label="Standard or Framework"
              keyName="framework_or_standard"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RefIDFilter inputs={inputs} setInputs={setInputs} />
          </section>
          <RegularInput
            label="Description"
            keyName="description"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                addRisk.mutate({
                  info: inputs,
                });

                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addRisk.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New threat has been created"
      />
    </>
  );
};

export default NewThreat;
