import React from "react";
import { convertToUTCString } from "../../../../../utils/general";
import {
  attributeColors,
  attributeTextColors,
} from "../../../../../constants/general";
import MetadataField from "src/components/ERC/MetadataField";
import SendEmail from "./SendEmail";
import UpdateAssessment from "./UpdateAssessment";
import ArchiveRestoreAssessment from "./ArchiveRestoreAssessment";
import { GetAssessmentMetadata } from "src/services/erc/assessments/assessments";

const AssessmentMetadata = ({
  entityID,
  assessmentID,
}: {
  entityID: string;
  assessmentID: string;
}) => {
  const { data: assessmentMetadata } = GetAssessmentMetadata(
    assessmentID,
    entityID,
    "ERC",
    "ENTITY"
  );

  return (
    <>
      {assessmentMetadata && (
        <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
          <header className="flex items-start justify-between gap-5">
            <article className="grid content-start gap-1">
              <h2 className="text-subt1-semi">{assessmentMetadata.title}</h2>
              <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                <span>
                  {convertToUTCString(assessmentMetadata.start_date)} -{" "}
                  {convertToUTCString(assessmentMetadata.end_date)}
                </span>
              </article>
            </article>
            <article className="flex items-center gap-5">
              {assessmentMetadata?.is_open && (
                <SendEmail
                  entityID={entityID}
                  assessmentID={assessmentID}
                  assessment={assessmentMetadata}
                />
              )}
              {/* <UpdateAssessment
                assessmentID={assessmentID}
                assessment={assessmentMetadata}
              /> */}
              {/* <ArchiveRestoreAssessment
                assessmentID={assessmentID}
                assessment={assessmentMetadata}
              /> */}
            </article>
          </header>
          <section className="flex flex-wrap items-center gap-5 text-b1-reg">
            <article
              className={`flex items-center gap-1 ${
                attributeColors[
                  String(assessmentMetadata.is_open)?.toLowerCase()
                ]
              }`}
            >
              Status
              <span
                className={`capitalize ${
                  attributeTextColors[
                    String(assessmentMetadata.is_open)?.toLowerCase()
                  ]
                }`}
              >
                {assessmentMetadata.is_open ? "OPEN" : "CLOSED"}
              </span>
            </article>
          </section>
          <MetadataField
            metadata={assessmentMetadata}
            label="Purpose"
            keyName="purpose"
          />
        </section>
      )}
    </>
  );
};

export default AssessmentMetadata;
