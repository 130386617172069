import React from "react";
import {
  convertToUTCString,
  getCustomerID,
  getEmailFromUserID,
  getNamefromUserID,
} from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetEntityTypeMetadata } from "../../../../../services/erc/entities/entity-types";
import { GetAllUsers } from "src/services/settings/users";
import MetadataField from "src/components/ERC/MetadataField";
import UpdateEntityType from "./UpdateEntityType";
import ArchiveRestoreEntityType from "./ArchiveRestoreEntityType";

const EntityTypeMetadata = ({ entityTypeID }: { entityTypeID: string }) => {
  const customerID = getCustomerID();

  const { data: entityTypeMetadata } = GetEntityTypeMetadata(entityTypeID);
  const { data: allUsers } = GetAllUsers(customerID, false);

  return (
    <>
      {entityTypeMetadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-5">
              <article className="grid content-start gap-1">
                <h2 className="text-subt1-semi">{entityTypeMetadata.name}</h2>
                <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                  <h4>
                    Last Updated by{" "}
                    {getNamefromUserID(
                      allUsers,
                      entityTypeMetadata.last_updated_by
                    )}{" "}
                    (
                    {getEmailFromUserID(
                      allUsers,
                      entityTypeMetadata.last_updated_by
                    )}
                    )
                  </h4>
                  <span>
                    | {convertToUTCString(entityTypeMetadata.last_updated_at)}
                  </span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    Created by{" "}
                    {getNamefromUserID(allUsers, entityTypeMetadata.created_by)}{" "}
                    (
                    {getEmailFromUserID(
                      allUsers,
                      entityTypeMetadata.created_by
                    )}
                    )
                  </h4>
                  <span>
                    | {convertToUTCString(entityTypeMetadata.created_at)}
                  </span>
                </article>
              </article>
              <article className="flex items-center gap-5">
                <UpdateEntityType
                  entityTypeID={entityTypeID}
                  entityType={entityTypeMetadata}
                />
                {/* <ArchiveRestoreEntityType
                  entityTypeID={entityTypeID}
                  entityType={entityTypeMetadata}
                /> */}
              </article>
            </header>
            <section className="flex flex-wrap items-center gap-2 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[entityTypeMetadata.status.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[entityTypeMetadata.status.toLowerCase()]
                  }`}
                >
                  {entityTypeMetadata.status}
                </span>
              </article>
            </section>
            <MetadataField
              metadata={entityTypeMetadata}
              label="Description"
              keyName="description"
            />
          </section>
        </header>
      )}
    </>
  );
};

export default EntityTypeMetadata;
