import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { GetControlBlueprints } from "../../../services/erc/controls/control-blueprints";
import { KeyStringVal } from "../../../types/general";

const MultiControlBlueprintFilter = ({
  label,
  keyName,
  inputs,
  setInputs,
  required,
}: {
  label: string;
  keyName: string;
  inputs: any;
  setInputs: (inputs: any) => void;
  required?: boolean;
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [hover, setHover] = useState<string>("");

  const { data: controlBlueprints } = GetControlBlueprints();

  const filteredItems = controlBlueprints?.data.filter((item: KeyStringVal) =>
    item.name
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(query.toLowerCase().replace(/\s+/g, ""))
  );

  return (
    <section className="grid grid-cols-2 gap-5 w-full text-b2-reg">
      <article className="grid content-start">
        <header className="flex items-center gap-1 justify-self-start">
          <h4>{label}</h4>
          {required && <span className="text-b1-reg text-red-500">*</span>}
        </header>
        <article
          onMouseLeave={() => setShowDropdown(false)}
          onClick={() => setShowDropdown(true)}
          className="relative px-4 py-2 w-full h-9 dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 focus:outline-none rounded"
        >
          <article className="flex items-center justify-between gap-2">
            <input
              type="input"
              autoComplete="off"
              spellCheck="false"
              placeholder={`${inputs[keyName].length} selected`}
              value={query}
              onChange={(e) => {
                if (!showDropdown) setShowDropdown(true);
                setQuery(e.target.value);
              }}
              className="w-full placeholder:normal-case capitalize border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
            />
            <span className="flex items-center pointer-events-none">
              <ChevronDownIcon
                className="w-5 h-5 dark:text-gray-500"
                aria-hidden="true"
              />
            </span>
          </article>
          {showDropdown && (
            <article className="absolute top-9 left-0 grid gap-1 p-1 content-start w-full max-h-36 dark:bg-gray-900 focus:outline-none shadow dark:shadow-black overflow-auto scrollbar rounded-b-sm z-10">
              {filteredItems?.map((item: KeyStringVal) => {
                const selected = inputs[keyName].includes(
                  item.control_blueprint_id
                );
                return (
                  <button
                    key={item.control_blueprint_id}
                    className={`relative flex items-start gap-2 capitalize px-4 py-1 text-left break-words dark:hover:bg-gray-800 duration-100 ${
                      selected ? "dark:bg-gray-800" : ""
                    } rounded-lg`}
                    onMouseEnter={() => setHover(item.description)}
                    onMouseLeave={(e) => {
                      e.stopPropagation();

                      setHover("");
                    }}
                    onClick={() => {
                      setQuery("");
                      if (selected)
                        setInputs({
                          ...inputs,
                          [keyName]: inputs[keyName].filter(
                            (blueprintID) =>
                              item.control_blueprint_id !== blueprintID
                          ),
                        });
                      else
                        setInputs({
                          ...inputs,
                          [keyName]: [
                            ...inputs[keyName],
                            item.control_blueprint_id,
                          ],
                        });
                    }}
                  >
                    {item.name}
                  </button>
                );
              })}
            </article>
          )}
        </article>
        <ul className="flex flex-wrap items-center gap-2 mt-1">
          {inputs[keyName].map((blueprintID: string) => {
            const value =
              controlBlueprints?.data?.find(
                (blueprint) => blueprint.control_blueprint_id === blueprintID
              )?.name || "";

            const description =
              controlBlueprints?.data?.find(
                (blueprint) => blueprint.control_blueprint_id === blueprintID
              )?.description || "";
            return (
              <li
                key={blueprintID}
                className="grid gap-1 px-2 py-1 dark:bg-gray-600 rounded-md"
              >
                <h4 className="text-b2-semi">{value}</h4>
                <p>{description}</p>
              </li>
            );
          })}
        </ul>
      </article>
      {hover && (
        <article className="grid gap-1 px-2 py-1 dark:bg-gray-600 rounded-md">
          <p>{hover}</p>
        </article>
      )}
    </section>
  );
};

export default MultiControlBlueprintFilter;
