import { faLink, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import DocumentTypeFilter from "src/components/Filter/VendorWebform/DocumentTypeFilter";
import SelectDocFrameworkFilter from "src/components/Filter/VendorWebform/SelectDocFrameworkFilter";
import AdditionalDocsInput from "src/components/Input/AdditionalDocsInput";
import ModalLayout from "src/layouts/ModalLayout";
import { convertBytes } from "src/utils/general";

const UploadAdditionalDocuments = ({
  inputs,
  setInputs,
}: {
  inputs: any;
  setInputs: any;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [additionalDocs, setAdditionalDocs] = useState<any>({
    files: [],
    document_types: [],
    frameworks: [],
  });

  const handleOnClose = () => setShow(false);

  return (
    <section className="flex flex-col flex-grow gap-5">
      <button
        className="black-button"
        onClick={() => {
          setShow(true);
          setAdditionalDocs({
            files: [],
            document_types: [],
            frameworks: [],
          });
        }}
      >
        <h4>Upload Additional Documents</h4>
      </button>
      {inputs.files?.length > 0 && (
        <ul className="grid gap-3 list-disc my-4">
          {inputs.files?.map((file: any, fileIndex: number) => {
            return (
              <li
                key={fileIndex}
                className="flex flex-col flex-grow gap-1 w-full"
              >
                <header className="flex flex-wrap items-center gap-1">
                  <FontAwesomeIcon icon={faLink} />
                  <h4>
                    {fileIndex + 1}. {file.name}
                  </h4>
                  <span>({convertBytes(file.size)})</span>
                </header>
                <article className="flex flex-wrap lg:flex-nowrap items-center gap-7 justify-content-end">
                  <DocumentTypeFilter
                    label="Type"
                    fileIndex={fileIndex}
                    inputs={inputs}
                    setInputs={setInputs}
                  />
                  {["Audit Reports", "Privacy Documents"].includes(
                    inputs.document_types[fileIndex]
                  ) && (
                    <SelectDocFrameworkFilter
                      label="Framework"
                      fileIndex={fileIndex}
                      inputs={inputs}
                      setInputs={setInputs}
                      type={inputs.document_types[fileIndex]}
                    />
                  )}
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="cursor-pointer trash-button"
                    onClick={(e) =>
                      setInputs({
                        ...inputs,
                        files: [
                          ...inputs.files.filter(
                            (_: any, curFileIndex: number) =>
                              curFileIndex !== fileIndex
                          ),
                        ],
                        document_types: [
                          ...inputs.document_types.filter(
                            (_: any, curFileIndex: number) =>
                              curFileIndex !== fileIndex
                          ),
                        ],
                        frameworks: [
                          ...inputs.frameworks.filter(
                            (_: any, curFileIndex: number) =>
                              curFileIndex !== fileIndex
                          ),
                        ],
                      })
                    }
                  />
                </article>
              </li>
            );
          })}
        </ul>
      )}
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid gap-5">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Upload Additional Documents
          </h3>
          <AdditionalDocsInput
            label="Documents"
            types={["pdf", "docx"]}
            inputs={additionalDocs}
            setInputs={setAdditionalDocs}
          />
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                additionalDocs.files.length === 0 ||
                additionalDocs.document_types.length === 0 ||
                (additionalDocs.document_types.length > 0 &&
                  additionalDocs.document_types.some(
                    (documentType: string) => !documentType
                  )) ||
                (additionalDocs.document_types.length > 0 &&
                  additionalDocs.document_types.some(
                    (documentType: string, docTypeIdx: number) =>
                      ["Audit Reports", "Privacy Documents"].includes(
                        documentType
                      ) && !additionalDocs.frameworks[docTypeIdx]
                  ))
              }
              className="blue-button"
              onClick={() => {
                setInputs({
                  files: [...inputs.files, ...additionalDocs.files],
                  document_types: [
                    ...inputs.document_types,
                    ...additionalDocs.document_types,
                  ],
                  frameworks: [
                    ...inputs.frameworks,
                    ...additionalDocs.frameworks,
                  ],
                });
                handleOnClose();
              }}
            >
              Done
            </button>
          </article>
        </section>
      </ModalLayout>
    </section>
  );
};

export default UploadAdditionalDocuments;
