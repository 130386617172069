import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import CategoryInput from "../../../../components/Input/CategoryInput";
import { AddEntityType } from "../../../../services/erc/entities/entity-types";
import ToastLayout from "../../../../layouts/ToastLayout";
import MultiControlBlueprintFilter from "src/components/Filter/ERC/MultiControlBlueprintFilter";
import CheckboxInput from "src/components/Input/CheckboxInput";

const NewEntityType = () => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: "",
    description: "",
    status: "DRAFT",
    control_blueprint_ids: [],
    create_controls_automatically_enabled: false,
  });
  const [valid, setValid] = useState<boolean>(true);

  const addEntityType = AddEntityType();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addEntityType.reset();

  return (
    <>
      <button
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            description: "",
            status: "DRAFT",
            control_blueprint_ids: [],
            create_controls_automatically_enabled: false,
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Entity Type</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 w-full h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Add Entity Type
          </h3>
          <section className="grid gap-5 w-full">
            <section className="grid md:grid-cols-2 gap-5">
              <RegularInput
                label="Name"
                keyName="name"
                inputs={inputs}
                setInputs={setInputs}
                valid={valid}
                setValid={setValid}
                required
              />
              <CategoryInput
                label="Status"
                keyName="status"
                list={["DRAFT", "INACTIVE", "ACTIVE", "ARCHIVED"]}
                inputs={inputs}
                setInputs={setInputs}
                allowUnselect
                required
              />
            </section>
            <MultiControlBlueprintFilter
              label="Control Blueprints"
              keyName="control_blueprint_ids"
              inputs={inputs}
              setInputs={setInputs}
            />
          </section>
          {inputs.control_blueprint_ids.length > 0 && (
            <CheckboxInput
              label="Create Controls Automatically"
              keyName="create_controls_automatically_enabled"
              inputs={inputs}
              setInputs={setInputs}
            />
          )}
          <RegularInput
            label="Description"
            keyName="description"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === "" || inputs.status === ""}
              className="blue-button"
              onClick={() => {
                addEntityType.mutate({
                  info: inputs,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addEntityType.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New entity type has been created"
      />
    </>
  );
};

export default NewEntityType;
