import React from "react";
import {
  convertToUTCString,
  getEmailFromUserID,
  getNamefromUserID,
} from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetControlBlueprintMetadata } from "../../../../../services/erc/controls/control-blueprints";
import { GetAllUsers } from "../../../../../services/settings/users";
import { getCustomerID } from "../../../../../utils/general";
import MetadataField from "src/components/ERC/MetadataField";
import ArchiveRestoreBlueprint from "./ArchiveRestoreBlueprint";
import UpdateControlBlueprint from "./UpdateControlBlueprint";

const ControlBlueprintMetadata = ({ blueprintID }: { blueprintID: string }) => {
  const customerID = getCustomerID();

  const { data: controlBlueprintMetadata } =
    GetControlBlueprintMetadata(blueprintID);
  const { data: allUsers } = GetAllUsers(customerID, false);

  return (
    <>
      {controlBlueprintMetadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-center justify-between gap-10">
              <article className="flex flex-col flex-grow gap-1">
                <h2 className="text-subt1-semi">
                  {controlBlueprintMetadata.name}
                </h2>
                <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                  <h4>
                    Last Updated by{" "}
                    {getNamefromUserID(
                      allUsers,
                      controlBlueprintMetadata.last_updated_by
                    )}{" "}
                    (
                    {getEmailFromUserID(
                      allUsers,
                      controlBlueprintMetadata.last_updated_by
                    )}
                    )
                  </h4>
                  <span>
                    |{" "}
                    {convertToUTCString(
                      controlBlueprintMetadata.last_updated_at
                    )}
                  </span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    Created by{" "}
                    {getNamefromUserID(
                      allUsers,
                      controlBlueprintMetadata.created_by
                    )}{" "}
                    (
                    {getEmailFromUserID(
                      allUsers,
                      controlBlueprintMetadata.created_by
                    )}
                    )
                  </h4>
                  <span>
                    | {convertToUTCString(controlBlueprintMetadata.created_at)}
                  </span>
                </article>
              </article>
              {/* <article className="flex items-center gap-5">
                <UpdateControlBlueprint
                  blueprintID={blueprintID}
                  blueprint={controlBlueprintMetadata}
                />
                <ArchiveRestoreBlueprint
                  blueprintID={blueprintID}
                  blueprint={controlBlueprintMetadata}
                />
              </article> */}
            </header>
            <section className="flex flex-wrap items-center gap-2 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[controlBlueprintMetadata.status.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[
                      controlBlueprintMetadata.status.toLowerCase()
                    ]
                  }`}
                >
                  {controlBlueprintMetadata.status}
                </span>
              </article>
              {controlBlueprintMetadata.create_controls_automatically_enabled && (
                <article
                  className={`flex items-center gap-1 ${attributeColors.true}`}
                >
                  Create Controls Automatically
                </article>
              )}
            </section>
            <MetadataField
              metadata={controlBlueprintMetadata}
              label="Description"
              keyName="description"
            />
            <section className="grid grid-cols-5 gap-x-20 gap-y-10">
              <MetadataField
                metadata={controlBlueprintMetadata}
                label="Type"
                keyName="type"
              />
              <MetadataField
                metadata={controlBlueprintMetadata}
                label="Category"
                keyName="category"
              />
              <MetadataField
                metadata={controlBlueprintMetadata}
                label="Subcategory"
                keyName="sub_category"
              />
              <MetadataField
                metadata={controlBlueprintMetadata}
                label="Classification"
                keyName="classification"
              />
              <MetadataField
                metadata={controlBlueprintMetadata}
                label="Attestation"
                keyName="attestation"
              />
              <MetadataField
                metadata={controlBlueprintMetadata}
                label="Owner"
                keyName="owner"
                singleUser
              />
            </section>
          </section>
        </header>
      )}
    </>
  );
};

export default ControlBlueprintMetadata;
