/* eslint-disable react-hooks/exhaustive-deps */
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { KeyStringVal } from "src/types/general";
import { SearchVulnerability } from "src/services/erc/risks/risks";

const VulnerabilitiesFilter = ({
  label,
  inputs,
  setInputs,
}: {
  label?: string;
  inputs: any;
  setInputs: (inputs: any) => void;
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const search = SearchVulnerability();

  const handleSelect = (vulnID: string) => {
    if (inputs.vulnerabilities.includes(vulnID))
      setInputs({
        ...inputs,
        vulnerabilities: inputs.vulnerabilities.filter(
          (curVulnID: string) => curVulnID !== vulnID
        ),
      });
    else
      setInputs({
        ...inputs,
        vulnerabilities: [...inputs.vulnerabilities, vulnID],
      });
  };

  useEffect(() => {
    search.mutate({
      vulnerabilityName: "",
    });
  }, []);

  return (
    <section className="grid content-start text-b2-reg text-left">
      {label && <h4>{label}</h4>}
      <article
        onMouseLeave={() => setShowDropdown(false)}
        onClick={() => setShowDropdown(true)}
        className="relative px-4 py-1 w-full cursor-pointer dark:bg-gray-900 dark:hover:bg-gray-900/60 duration-100 focus:outline-none rounded-sm"
      >
        <article className="flex items-center justify-between gap-2">
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder={`${
              inputs.vulnerabilities.length > 0
                ? `${inputs.vulnerabilities.length} selected`
                : "Select"
            }`}
            value={query}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
              setQuery(e.target.value);
              search.mutate({
                vulnerabilityName: e.target.value,
              });
            }}
            className="w-full border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
          </span>
        </article>
        {showDropdown && (
          <article className="absolute top-8 right-0 grid content-start w-full max-h-36 dark:bg-gray-700 focus:outline-none overflow-auto scrollbar rounded-b-sm z-10">
            {search.data ? (
              search.data.length > 0 ? (
                <article className="flex flex-col flex-grow gap-1">
                  <article className="flex items-center gap-5 px-4 py-1 divide-x dark:divide-checkbox">
                    <button
                      className="dark:disabled:text-filter dark:hover:text-checkbox/60 duration-100"
                      onClick={() =>
                        search.mutate(
                          {
                            vulnerabilityName: "",
                          },
                          {
                            onSuccess: (data) => {
                              const vulnIDs = data.reduce(
                                (pV: string[], cV: KeyStringVal) => [
                                  ...pV,
                                  cV.vulnerability_id,
                                ],
                                []
                              );
                              setInputs({
                                ...inputs,
                                vulnerabilities: vulnIDs,
                              });
                            },
                          }
                        )
                      }
                    >
                      Select All
                    </button>
                    <button
                      disabled={inputs.vulnerabilities.length === 0}
                      className="pl-5 dark:disabled:text-filter dark:hover:text-checkbox/60 duration-100"
                      onClick={() =>
                        setInputs({ ...inputs, vulnerabilities: [] })
                      }
                    >
                      Deselect All
                    </button>
                  </article>
                  {search.data?.map((item: KeyStringVal) => {
                    const selected = inputs.vulnerabilities.includes(
                      item.vulnerability_id
                    );

                    return (
                      <button
                        key={item.vulnerability_id}
                        className="relative name flex gap-1 px-4 py-1 text-left break-words cursor-pointer dark:hover:bg-gray-600/70 duration-100"
                        onClick={() => handleSelect(item.vulnerability_id)}
                      >
                        {selected && (
                          <FontAwesomeIcon icon={faCheck} className="text-no" />
                        )}
                        {item.name}
                      </button>
                    );
                  })}
                </article>
              ) : (
                <p className="px-4 py-1">No results found</p>
              )
            ) : null}
          </article>
        )}
      </article>
    </section>
  );
};

export default VulnerabilitiesFilter;
