/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { KeyStringVal } from "../../../types/general";
import { GetCustomQuestionSets } from "src/services/third-party-risk/questions/custom-question-sets";

const AssessmentQuestionSetFilter = ({
  label,
  keyName,
  inputs,
  setInputs,
  required,
}: {
  label?: string;
  keyName: string;
  inputs: any;
  setInputs: any;
  required?: boolean;
}) => {
  const { data: questionSets } = GetCustomQuestionSets();

  const questionSetID = inputs[keyName] || "";
  const questionSetName =
    questionSets?.data.find(
      (questionSet) => questionSet.generated_id === questionSetID
    )?.name || "";

  return (
    <DropdownLayout
      label={label}
      value={questionSetName}
      placeholder="Select"
      labelAbove
      required={required}
    >
      {questionSets?.data.map((questionSet: KeyStringVal, index: number) => {
        const selected = inputs[keyName] === questionSet.generated_id;

        return (
          <button
            key={index}
            className={`relative px-4 py-1 w-full text-left dark:hover:bg-gray-600/70 ${
              questionSetID === questionSet.generated_id ? "dark:bg-filter" : ""
            } duration-100`}
            onClick={() => {
              if (selected)
                setInputs({
                  ...inputs,
                  [keyName]: "",
                });
              else
                setInputs({
                  ...inputs,
                  [keyName]: questionSet.generated_id,
                });
            }}
          >
            <p>{questionSet.name}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default AssessmentQuestionSetFilter;
