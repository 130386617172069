/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  GenerateTreatmentPlan,
  GetRiskMetadata,
} from "src/services/erc/risks/risks";

const TreatmentPlan = ({ riskID }: { riskID: string }) => {
  const { data: riskMetadata } = GetRiskMetadata(riskID);
  const generateTreatmentPlan = GenerateTreatmentPlan(riskID);

  return (
    <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
      <header className="flex items-center gap-5">
        <h4 className="text-t1-semi">Treatment Plan</h4>
        {!riskMetadata?.is_treatment_plan_generated && (
          <button
            className="blue-button"
            onClick={() => generateTreatmentPlan.mutate({})}
          >
            Generate Treatment Plan
          </button>
        )}
      </header>
      {riskMetadata?.generated_treatment_plan && (
        <ReactMarkdown
          className="min-w-full prose dark:prose-invert dark:bg-gray-800 rounded-xl"
          remarkPlugins={[remarkGfm]}
        >
          {riskMetadata?.generated_treatment_plan}
        </ReactMarkdown>
      )}
    </section>
  );
};

export default TreatmentPlan;
