import React from "react";

const RadioInput = ({
  label,
  keyName,
  list,
  inputs,
  setInputs,
  required,
}: {
  label: string;
  keyName: string;
  list: string[];
  inputs: any;
  setInputs: (inputs: any) => void;
  required?: boolean;
}) => {
  return (
    <article className="flex flex-col flex-grow gap-1 text-b2-reg">
      <header className="flex items-center gap-1 justify-self-start">
        <h4>{label}</h4>
        {required && <span className="text-h5 text-red-500">*</span>}
      </header>
      <article className="flex items-center gap-5">
        {list?.map((value) => {
          return (
            <article key={value} className="flex items-center gap-2">
              <input
                type="radio"
                checked={inputs[keyName] === value}
                onClick={() =>
                  setInputs({
                    ...inputs,
                    [keyName]: value,
                  })
                }
                className="form-radio w-4 h-4 border-0 dark:focus:ring-0 dark:text-blue-500 dark:focus:border-blue-500 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-500 focus:ring-opacity-50"
              />
              <label className="w-max capitalize">{value}</label>
            </article>
          );
        })}
      </article>
    </article>
  );
};

export default RadioInput;
