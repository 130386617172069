import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import { AddRiskBlueprint } from "../../../../services/erc/risks/risk-blueprints";
import ToastLayout from "../../../../layouts/ToastLayout";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import MultiUserInput from "src/components/Input/MultiUserInput";
import CheckboxInput from "src/components/Input/CheckboxInput";
import AssessmentQuestionnaireFilter from "src/components/Filter/ERC/AssessmentQuestionnaireFilter";
import CategoryInput from "src/components/Input/CategoryInput";

const NewRiskBlueprint = () => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: "",
    description: "",
    threat: "",
    vulnerability: "",
    category: "",
    sub_category: "",
    classification: "",
    type: "",
    owner_same_as_entity_owner: true,
    owners: [],
    owners_text: [],
    reviewers: [],
    reviewers_text: [],
    approvers: [],
    approvers_text: [],
    treatment: "",
    treatment_plan: "",
    deadline: Date.now() * 1000,
    create_risks_automatically: true,
    inherent_risk_score: 0,
    inherent_risk_impact_level: "",
    inherent_risk_likelihood_level: "",
    assessment: "",
    treatment_type: "MITIGATE",
    status: "DRAFT",
    treatment_status: "",
    compensating_controls: "",
    additional_information: "",
  });
  const [valid, setValid] = useState<boolean>(true);

  const addRiskBlueprint = AddRiskBlueprint();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addRiskBlueprint.reset();

  return (
    <>
      <button
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            description: "",
            threat: "",
            vulnerability: "",
            category: "",
            sub_category: "",
            classification: "",
            type: "",
            owner_same_as_entity_owner: true,
            owners: [],
            owners_text: [],
            reviewers: [],
            reviewers_text: [],
            approvers: [],
            approvers_text: [],
            treatment: "",
            treatment_plan: "",
            deadline: Date.now() * 1000,
            create_risks_automatically: true,
            inherent_risk_score: 0,
            inherent_risk_impact_level: "MODERATE",
            inherent_risk_likelihood_level: "POSSIBLE",
            assessment: "",
            treatment_type: "MITIGATE",
            status: "DRAFT",
            treatment_status: "",
            compensating_controls: "",
            additional_information: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Risk Blueprint</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 w-full h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Add Risk Blueprint
          </h3>
          <section className="grid md:grid-cols-2 gap-5">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <RegularInput
              label="Classification"
              keyName="classification"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Type"
              keyName="type"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Category"
              keyName="category"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Subcategory"
              keyName="sub_category"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Threat"
              keyName="threat"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Vulnerability"
              keyName="vulnerability"
              inputs={inputs}
              setInputs={setInputs}
            />
            <MultiUserInput
              label="Owners"
              keyName="owners"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
              allowNewValue
            />
            <AssessmentQuestionnaireFilter
              label="Assessment"
              keyName="assessment"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CheckboxInput
              label="Create Risks Automatically"
              keyName="create_risks_automatically"
              inputs={inputs}
              setInputs={setInputs}
            />
          </section>
          <section className="grid md:grid-cols-2 gap-5">
            <MultiUserInput
              label="Reviewers"
              keyName="reviewers"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
              allowNewValue
            />
            <MultiUserInput
              label="Approvers"
              keyName="approvers"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
              allowNewValue
            />
            <CategoryInput
              label="Treatment Type"
              keyName="treatment_type"
              list={["ACCEPT", "AVOID", "MITIGATE", "TRANSFER"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <GeneralSnapshotDatepicker
              label="Deadline"
              keyName="deadline"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CategoryInput
              label="Treatment Status"
              keyName="treatment_status"
              list={["Open", "Closed", "Resolved", "Pending"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <RegularInput
              label="Treatment Plan"
              keyName="treatment_plan"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CategoryInput
              label="Inherent Risk Impact Level"
              keyName="inherent_risk_impact_level"
              list={[
                "NEGLIGIBLE",
                "MINOR",
                "MODERATE",
                "SIGNIFICANT",
                "SEVERE",
              ]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <CategoryInput
              label="Inherent Risk Likelihood Level"
              keyName="inherent_risk_likelihood_level"
              list={[
                "VERY_UNLIKELY",
                "UNLIKELY",
                "POSSIBLE",
                "LIKELY",
                "VERY_LIKELY",
              ]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <CategoryInput
              label="Status"
              keyName="status"
              list={["DRAFT", "INACTIVE", "ACTIVE", "ARCHIVED"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <RegularInput
              label="Compensating Controls"
              keyName="compensating_controls"
              inputs={inputs}
              setInputs={setInputs}
            />
          </section>
          <RegularInput
            label="Description"
            keyName="description"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <RegularInput
            label="Additional Information"
            keyName="additional_information"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                addRiskBlueprint.mutate({
                  info: inputs,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addRiskBlueprint.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New risk blueprint has been created"
      />
    </>
  );
};

export default NewRiskBlueprint;
