/* eslint-disable react-hooks/exhaustive-deps */
import {
  faGripVertical,
  faPlus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { EditGlobalQuestion } from "src/services/third-party-risk/questions/global-questions";
import DeleteGlobalQuestion from "./DeleteGlobalQuestion";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import QuestionTags from "./QuestionTags";
import QuestionTypeFilter from "src/components/Filter/ThirdPartyRisk/QuestionTypeFilter";
import { KeyStringVal } from "src/types/general";
import { attributeColors } from "src/constants/general";

const Response = ({
  qa,
  selectedQuestions,
  setSelectedQuestions,
  filteredMetadata,
}: {
  qa: any;
  selectedQuestions: any;
  setSelectedQuestions: any;
  filteredMetadata?: any;
}) => {
  const questionID = qa.generated_id;
  const [inputs, setInputs] = useState({
    question: qa.question,
    response: qa.response,
    multi_choices: qa.multi_choices,
    multi_answers: qa.multi_answers,
    question_type: qa.question_type,
    description: qa.description,
    weight: qa.weight,
    question_mandatory: qa.question_mandatory,
    attachment_mandatory: qa.attachment_mandatory,
  });
  const [show, setShow] = useState<boolean>(false);
  const [newChoice, setNewChoice] = useState("");

  const { attributes, listeners, transform, transition, setNodeRef } =
    useSortable({
      id: questionID,
    });

  const editResponse = EditGlobalQuestion(questionID);

  const handleDiscardEditedQuestion = () =>
    setInputs({ ...inputs, question: qa.question });
  const handleDiscardEditedAnswer = () =>
    setInputs({ ...inputs, response: qa.response });

  const selected = selectedQuestions.some(
    (question) => question.generated_id === questionID
  );
  const notYN = inputs.question_type !== "Yes_No";
  const filteredChoices = notYN
    ? inputs.multi_choices
    : [{ choice: "Yes" }, { choice: "No" }];

  useEffect(() => {
    if (qa.multi_choices?.length > 0 && inputs.multi_choices.length === 0)
      setInputs({
        ...inputs,
        multi_choices: qa.multi_choices,
      });
    if (qa.multi_answers?.length > 0 && inputs.multi_answers.length === 0)
      setInputs({
        ...inputs,
        multi_answers: qa.multi_answers,
      });
  }, [qa.multi_choices, qa.multi_answers]);

  useEffect(() => {
    handleDiscardEditedQuestion();
    handleDiscardEditedAnswer();
  }, [qa]);

  return (
    <tr
      data-test="table-row"
      style={{
        transform: CSS.Transform.toString(transform),
        transition: transition,
      }}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className="relative p-5 gap-3 w-full break-words dark:bg-gray-800"
    >
      {filteredMetadata?.map((col: string, colIndex: number) => {
        return (
          <td
            key={`${questionID}-${colIndex}`}
            className="relative px-6 py-3 last:pr-16 text-left"
          >
            <article className="flex items-start gap-2">
              {colIndex === 0 && (
                <article className="flex items-center gap-2 mt-2">
                  <button className="hover:cursor-grab">
                    <FontAwesomeIcon
                      icon={faGripVertical}
                      className="dark:text-gray-500"
                    />
                  </button>
                  <input
                    type="checkbox"
                    checked={selected}
                    onChange={() => {
                      if (selected)
                        setSelectedQuestions(
                          selectedQuestions.filter(
                            (curQuestion: KeyStringVal) =>
                              curQuestion.generated_id !== questionID
                          )
                        );
                      else setSelectedQuestions([...selectedQuestions, qa]);
                    }}
                    className="form-checkbox w-4 h-4 border-0 dark:focus:ring-0 dark:text-blue-500 dark:focus:border-blue-500 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-500 focus:ring-opacity-50"
                  />
                </article>
              )}
              {col === "question" ? (
                <article className="grid content-start gap-3 w-full">
                  <textarea
                    value={inputs.question}
                    onChange={(e) =>
                      setInputs({ ...inputs, question: e.target.value })
                    }
                    className="px-4 py-2 w-full h-24 border-0 bg-transparent dark:hover:bg-gray-700 duration-100 dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 resize-none overflow-auto scrollbar rounded"
                  />
                  {qa.question !== inputs.question && (
                    <article className="flex items-center justify-self-end gap-2">
                      <button
                        className="red-button"
                        onClick={handleDiscardEditedQuestion}
                      >
                        Discard
                      </button>
                      <button
                        className="blue-button"
                        onClick={() => {
                          editResponse.mutate({
                            info: inputs,
                          });
                          handleDiscardEditedQuestion();
                        }}
                      >
                        Save
                      </button>
                    </article>
                  )}
                </article>
              ) : col === "response" ? (
                ["Yes_No", "Multiple Choice", "Multiple Select"].includes(
                  inputs.question_type
                ) ? (
                  <section className="flex flex-col flex-grow gap-2">
                    <section className="grid gap-1 w-full max-h-[30rem] overflow-auto scrollbar">
                      {filteredChoices?.map((choice: KeyStringVal) => {
                        const selected = inputs.multi_answers.some(
                          (selectedChoice) =>
                            selectedChoice.answer === choice.choice
                        );
                        return (
                          <article
                            key={choice.choice}
                            className="flex items-center gap-1 mx-1"
                          >
                            {inputs.question_type === "Multiple Select" ? (
                              <input
                                type="checkbox"
                                className="form-checkbox w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                                checked={selected}
                                onChange={() => {
                                  if (selected)
                                    setInputs({
                                      ...inputs,
                                      multi_answers:
                                        inputs.multi_answers.filter(
                                          (curChoice) =>
                                            curChoice.answer !== choice.choice
                                        ),
                                    });
                                  else
                                    setInputs({
                                      ...inputs,
                                      multi_answers: [
                                        ...inputs.multi_answers,
                                        { answer: choice.choice },
                                      ],
                                    });
                                }}
                              />
                            ) : (
                              <input
                                type="radio"
                                className="form-radio w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                                checked={selected}
                                onChange={() =>
                                  setInputs({
                                    ...inputs,
                                    multi_answers: [{ answer: choice.choice }],
                                  })
                                }
                              />
                            )}
                            <label htmlFor="">{choice.choice}</label>
                            {notYN && (
                              <button
                                className="red-trash-button"
                                onClick={() =>
                                  setInputs({
                                    ...inputs,
                                    multi_choices: inputs.multi_choices.filter(
                                      (curChoice) =>
                                        curChoice.choice !== choice.choice
                                    ),
                                    multi_answers: inputs.multi_answers.filter(
                                      (curChoice) =>
                                        curChoice.answer !== choice.choice
                                    ),
                                  })
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faXmark}
                                  className="mt-1"
                                />
                              </button>
                            )}
                          </article>
                        );
                      })}
                      {inputs.multi_answers.length > 0 && (
                        <button
                          className="place-self-end small-blue-button"
                          onClick={() => {
                            editResponse.mutate({
                              info: inputs,
                            });
                            setInputs({
                              ...inputs,
                              multi_choices: [],
                              multi_answers: [],
                            });
                          }}
                        >
                          Save
                        </button>
                      )}
                    </section>
                    {notYN && (
                      <article className="flex items-center gap-2 w-full h-10">
                        <textarea
                          value={newChoice}
                          placeholder="Add New Choice"
                          onChange={(e) => setNewChoice(e.target.value)}
                          className="px-4 py-2 w-full h-full overflow-hidden border-0 bg-transparent dark:hover:bg-gray-700 duration-100 dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 resize-none rounded"
                        />
                        <button
                          disabled={!newChoice}
                          className="justify-self-end w-full h-full blue-button"
                          onClick={() => {
                            setInputs({
                              ...inputs,
                              multi_choices: [
                                ...inputs.multi_choices,
                                { choice: newChoice },
                              ],
                            });
                            setNewChoice("");
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </article>
                    )}
                  </section>
                ) : inputs.question_type === "Text" ? (
                  <article className="grid content-start gap-3 w-full">
                    <textarea
                      value={inputs.response}
                      placeholder="Input text"
                      onChange={(e) =>
                        setInputs({ ...inputs, response: e.target.value })
                      }
                      className={`px-4 py-2 w-full ${
                        show
                          ? "h-40 overflow-auto scrollbar"
                          : "h-20 overflow-hidden"
                      } border-0 bg-transparent dark:hover:bg-gray-700 duration-100 dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 resize-none rounded`}
                    />
                    {qa.response !== inputs.response && (
                      <article className="flex items-center justify-self-end gap-2">
                        <button
                          className="red-button"
                          onClick={handleDiscardEditedAnswer}
                        >
                          Discard
                        </button>
                        <button
                          className="blue-button"
                          onClick={() => {
                            editResponse.mutate({
                              info: inputs,
                            });
                            handleDiscardEditedAnswer();
                          }}
                        >
                          Save
                        </button>
                      </article>
                    )}
                    {qa.response?.length >= 100 && (
                      <button
                        className="w-max underline dark:text-blue-400 dark:hover:text-blue-400/70 duration-100"
                        onClick={() => setShow(!show)}
                      >
                        {show ? "Hide" : "Show"}
                      </button>
                    )}
                  </article>
                ) : null
              ) : col === "question_type" ? (
                <QuestionTypeFilter inputs={inputs} setInputs={setInputs} />
              ) : col === "tags" ? (
                <QuestionTags questionID={questionID} tags={qa.tags} />
              ) : col === "question_mandatory" ? (
                <button
                  className={`${
                    attributeColors[String(qa[col])?.toLowerCase()]
                  }`}
                  onClick={() => {
                    editResponse.mutate({
                      info: {
                        ...inputs,
                        question_mandatory: !qa.question_mandatory,
                        attachment_mandatory: qa.attachment_mandatory,
                      },
                    });
                  }}
                >
                  {String(qa.question_mandatory)}
                </button>
              ) : col === "attachment_mandatory" ? (
                <button
                  className={`${
                    attributeColors[String(qa[col])?.toLowerCase()]
                  }`}
                  onClick={() => {
                    editResponse.mutate({
                      info: {
                        ...inputs,
                        question_mandatory: qa.question_mandatory,
                        attachment_mandatory: !qa.attachment_mandatory,
                      },
                    });
                  }}
                >
                  {String(qa.attachment_mandatory)}
                </button>
              ) : null}
              {colIndex === filteredMetadata.length - 1 && (
                <DeleteGlobalQuestion questionID={questionID} />
              )}
            </article>
          </td>
        );
      })}
    </tr>
  );
};

export default Response;
